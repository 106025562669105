<div class="row col-md-12">
  <div class="col-md-5">
    <label>Nome</label>
    <input type="text" class="form-control" placeholder="nome da campanha" [(ngModel)]="Campanha.nome">
  </div>
  <div class="col-md-5">
    <label>Descrição</label>
    <input type="text" class="form-control" placeholder="descrição da campanha" [(ngModel)]="Campanha.descricao">
  </div>
  <div class="col-md-2">
    <label>Habilitado</label>
    <input type="checkbox" class="form-control" [(ngModel)]="Campanha.habilitado">
  </div>

  <div class="col-md-3">
    <label>Abertura Inscrições</label>
    <input type="date" class="form-control" [(ngModel)]="Campanha.datainicial">
  </div>
  <div class="col-md-3">
    <label>Hora Inicial</label>
    <input type="time" class="form-control" [(ngModel)]="HoraAbertura">
  </div>
  <div class="col-md-3">
    <label>Encerrramento Inscrições</label>
    <input type="date" class="form-control" [(ngModel)]="Campanha.datafinal">
  </div>
  <div class="col-md-3">
    <label>Hora Final</label>
    <input type="time" class="form-control" [(ngModel)]="HoraFechamento">
  </div>

  <div class="col-md-12">
    <label *ngIf="txtInformativo != ''">{{txtInformativo}}</label>
  </div>

  <div class="col-md-12" style="margin-top: 1em;">
    <button class="btn btn-success" (click)="Salvar()">Salvar</button>
  </div>

  <div style="margin-top: 2em; border-top: 1px solid;">
    <p-table #dt [columns]="ColunasCampanhas" [value]="ListaCampanhas" [paginator]="true" [rows]="10" [responsive]="true">
        <ng-template pTemplate="caption" class="col-sm-4">
          <div style="text-align: right">
            <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
            <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt.filterGlobal($event.target.value, 'contains')">
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th>Ações</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
          <tr [pSelectableRow]="rowData">
            <td *ngFor="let col of columns">
              <span *ngIf="col.header.includes('Data')">{{rowData[col.field] | date: 'dd/MM/yyyy HH:mm'}}</span>
              <span *ngIf="!col.header.includes('Data')">{{rowData[col.field]}}</span>
            </td>
            <td>
                <button class="btn btn-warning" (click)="Editar(rowData)">Editar</button>
                <button class="btn btn-danger float-right" (click)="Excluir(rowData)">Excluir</button>
            </td>
          </tr>
        </ng-template>
    </p-table>
  </div>
</div>