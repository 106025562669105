import { ɵNOT_FOUND_CHECK_ONLY_ELEMENT_INJECTOR } from "@angular/core";

export class ParticipanteModel {
    nome: string = null;
    estado: string = "";
    cidade: string = "";
    telefone: string = null;
    email: string = null;
    datanascimento: Date = null;
    aceito: boolean = null;
}

export class ConsultorModel {
    nome: string = null;
    estado: string = "";
    cidade: string = "";
    unidade: string = null;
    email: string = null;
    telefone: string = null;
    aceito: boolean = null;
}