import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { DownloadsModel } from './downloadsModel';

@Injectable()
export class DownloadsService {

    Rota: String = this.globalFunc.APIUrl() + "/apicap";

    constructor(
      private http: HttpClient,
      private globalFunc: GlobalFunctions
    ) { }

    cadastrarDownload(formDownload: DownloadsModel) {
      return this.http.post<ResponseModel>(`${this.Rota}/cadastrarDownload`, formDownload)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    atualizarDownload(formDownload: DownloadsModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/atualizarDownload`, formDownload)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    mudaStatusDownload(iddownloads: number) {
      return this.http.get<ResponseModel>(`${this.Rota}/mudaStatusDownload/${iddownloads}`)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    removerDownload(iddownloads: number) {
      const response = this.http
        .delete(`${this.Rota}/removerDownload/${iddownloads}`)
        .toPromise();
      return response;
    }

    getAllDownloads() {
      return this.http.get<DownloadsModel[]>(`${this.Rota}/getAllDownloads`)
        .toPromise()
        .then(response => <DownloadsModel[]>response)
        .then(data => {
          return <DownloadsModel[]>data['response'];
        })
    }

    getAllDownloadsById(idcampanhas: number) {
      return this.http.get<DownloadsModel[]>(`${this.Rota}/getAllDownloadsById/${idcampanhas}`)
        .toPromise()
        .then(response => <DownloadsModel[]>response)
        .then(data => {
          return <DownloadsModel[]>data['response'];
        })
    }

    getDownloadsEnabled() {
      return this.http.get<DownloadsModel[]>(`${this.Rota}/getDownloadsEnabled`)
        .toPromise()
        .then(response => <DownloadsModel[]>response)
        .then(data => {
          return <DownloadsModel[]>data['response'];
        })
    }

}
