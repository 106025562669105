import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { CadUsuarioComponent } from './cad-usuario/cad-usuario.component';
import { CaptacaoComponent } from './captacao/captacao.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ConsultorService } from './cad-usuario/consultorService';
import { GlobalFunctions } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule } from 'ngx-mask';
import { LeadService } from './captacao/leadService';
import { CampanhaService } from './campanhas/campanhaService';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { ProvasComponent } from './provas/provas.component';
import { CampanhasComponent } from './campanhas/campanhas.component';
import { ProvaService } from './provas/provaService';
import { ParticipacaoprovaComponent } from './participacaoprova/participacaoprova.component';
import { CompartilhadoService } from './compartilhado/compartilhadoService';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingService } from './landing-page/landingService';
import { ConsultoresComponent } from './consultores/consultores.component';
import { QRCodeModule } from 'angularx-qrcode';
import { DownloadsComponent } from './downloads/downloads.component';
import { DownloadsService } from './downloads/downloadsService';
import { PastasService } from './downloads/pastasService';
import { PlacarComponent } from './placar/placar.component';
import { EnsinamaisComponent } from './ensinamais/ensinamais.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CadUsuarioComponent,
    CaptacaoComponent,
    DashboardComponent,
    ProvasComponent,
    CampanhasComponent,
    ParticipacaoprovaComponent,
    LandingPageComponent,
    ConsultoresComponent,
    DownloadsComponent,
    PlacarComponent,
    EnsinamaisComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    TableModule,
    DialogModule,
    ButtonModule,
    BrowserAnimationsModule,
    QRCodeModule
  ],
  exports: [
    TableModule,
    DialogModule,
    ButtonModule,
  ],
  providers: [
    GlobalFunctions,
    ConsultorService,
    LeadService,
    CampanhaService,
    ProvaService,
    CompartilhadoService,
    LandingService,
    DownloadsService,
    PastasService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
