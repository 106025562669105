import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { PastasModel } from './PastasModel';

@Injectable()
export class PastasService {

    Rota: String = this.globalFunc.APIUrl() + "/apicap";

    constructor(
      private http: HttpClient,
      private globalFunc: GlobalFunctions
    ) { }

    cadastrarPasta(formPasta: PastasModel) {
      return this.http.post<ResponseModel>(`${this.Rota}/cadastrarPasta`, formPasta)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    atualizarPasta(formPasta: PastasModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/atualizarPasta`, formPasta)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    mudaStatusPasta(idpastas: number) {
      return this.http.get<ResponseModel>(`${this.Rota}/mudaStatusPasta/${idpastas}`)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    removerPasta(idPastas: number) {
      const response = this.http
        .delete(`${this.Rota}/removerPasta/${idPastas}`)
        .toPromise();
      return response;
    }

    getAllPastas() {
      return this.http.get<PastasModel[]>(`${this.Rota}/getAllPastas`)
        .toPromise()
        .then(response => <PastasModel[]>response)
        .then(data => {
          return <PastasModel[]>data['response'];
        })
    }

    getAllPastasById(idcampanhas: number) {
      return this.http.get<PastasModel[]>(`${this.Rota}/getAllPastasById/${idcampanhas}`)
        .toPromise()
        .then(response => <PastasModel[]>response)
        .then(data => {
          return <PastasModel[]>data['response'];
        })
    }

    getPastasEnabled() {
      return this.http.get<PastasModel[]>(`${this.Rota}/getPastasEnabled`)
        .toPromise()
        .then(response => <PastasModel[]>response)
        .then(data => {
          return <PastasModel[]>data['response'];
        })
    }

}
