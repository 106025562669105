import { Component, OnInit } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { CampanhaService } from '../campanhas/campanhaService';
import { CampanhasModel } from '../campanhas/campanhasModel';
import { ProvaService } from './provaService';
import { ProvaAtualAttModel, ProvasModel } from './provasModel';

@Component({
  selector: 'app-provas',
  templateUrl: './provas.component.html',
  styleUrls: ['./provas.component.scss']
})
export class ProvasComponent implements OnInit {

  Prova: ProvasModel;
  ListaProvas: ProvasModel[];
  txtInformativo: string = "";
  ColunasProvas: any[];
  Campanhas: CampanhasModel[];
  GrupoProvas: any[];
  EditarProvas: boolean = false;
  CampanhaEscolhida: number;
  CampanhaTitulo: string;
  Provas: ProvasModel[];
  InsertProvaAtual: boolean = false;
  ProvaAtualAtt: ProvaAtualAttModel;

  constructor(
    private provaService: ProvaService,
    private campanhaService: CampanhaService,
    private globalFunc: GlobalFunctions,
  ) { }

  ngOnInit(): void {
    this.ColunasProvas = [
      { field: 'idprovas', header: 'ID' },
      { field: 'descricao', header: 'Descrição' },
      { field: 'repontuar', header: 'Repontuar' },
      { field: 'classificados', header: 'Classificados' },
      { field: 'habilitado', header: 'Habilitado' },
    ];
    this.AtualizarLista();
  }

  AtualizarLista() {
    this.campanhaService.getAllCampanhas().then(resCampanhas => {
      this.Campanhas = resCampanhas;
      this.Prova = new ProvasModel();
      this.provaService.getAllProvas().then(resProvas => {
        this.ListaProvas = resProvas;
        this.CreateProvasArray();
        //console.log(this.Campanhas, this.ListaProvas)
      });
    });
  }

  CreateProvasArray() {
    this.GrupoProvas = [];
    for(let i = 0; i < this.ListaProvas.length; i++){
      if(this.GrupoProvas.length == 0){
        this.GrupoProvas.push([this.ListaProvas[i]])
      } else {
        let exists = false;
        for(let j = 0; j < this.GrupoProvas.length; j++){
          if(this.GrupoProvas[j][0].idcampanhas == this.ListaProvas[i].idcampanhas){
            this.GrupoProvas[j].push(this.ListaProvas[i]);
            exists = true;
          }
        }
        if(!exists){
          this.GrupoProvas.push([this.ListaProvas[i]])
        }
      }
    }
    //console.log(this.GrupoProvas)
  }

  SelectCampanha(event){
    //console.log(this.CampanhaEscolhida);
    this.SelectProvas(this.CampanhaEscolhida);
  }
  
  SelectProvas(id) {
    //console.log(id)
    let exists = false;
    for(let i = 0; i < this.GrupoProvas.length; i++){
      if(this.GrupoProvas[i][0].idcampanhas == id){
        this.Provas = this.GrupoProvas[i];
        for(let j = 0; j < this.Campanhas.length; j++){
          if(this.Campanhas[j].idcampanhas == id){
            this.CampanhaTitulo = this.Campanhas[j].nome;
          }
        }
        exists = true;  
      }
    }
    if(!exists){
      this.CampanhaTitulo = "";
      for(let j = 0; j < this.Campanhas.length; j++){
        if(this.Campanhas[j].idcampanhas == id){
          this.CampanhaTitulo = this.Campanhas[j].nome;
        }
      }
      this.Provas = [];
    }
    this.EditarProvas = true;
    this.AtualizarLista();
    //console.log(this.Provas);
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      this.Prova.arquivo = event.target.files[0];
    }
  }

  Salvar() {
    this.Prova.idcampanhas = this.CampanhaEscolhida;
    if (this.Prova.idprovas > 0) { // UPDATE
      this.provaService.atualizarProva(this.Prova).then(resAtualizar => {
        this.AtualizarProvas();
        this.FimCadastro(resAtualizar);
      });
    }
    else { // INSERT
      this.provaService.cadastrarProva(this.Prova).then(resCadastro => {
        this.InsertProvaAtual = true;
        this.AtualizarProvas();
        this.FimCadastro(resCadastro);
      });
    }
  }

  FimCadastro(results: ResponseModel) {
    if (results.status == 200) {
      this.txtInformativo = "Prova cadastrada com sucesso";
      setTimeout(() => {
        this.txtInformativo = "";
        if (this.Prova.arquivo) {
          this.txtInformativo = "Copiando arquivo do jogo...";
          let Variavel: string = "UploadGame";
          this.globalFunc.uploadFiles([{header: "name", data: this.Prova.idprovas }, {header: "file", data: this.Prova.arquivo}], "POST", "/apicap/CopiarProva", Variavel);
          this.verifyEndUpload(Variavel);
        }
        else {
          this.AtualizarLista();
        }
      }, 800);
    }
    else {
      this.txtInformativo = "Erro: " + results.error;
      setTimeout(() => {
        this.txtInformativo = "";
      }, 5000);
    }
  }

  verifyEndUpload(Variavel: string) {
    setTimeout(() => {
      if (localStorage.getItem(Variavel) != "0") {
        this.txtInformativo = "Arquivo copiado com sucesso!";
        setTimeout(() => {
          this.txtInformativo = "";
        }, 1000);
        this.AtualizarLista();
      }
      else {
        this.verifyEndUpload(Variavel);
      }
    }, 200);
  }

  Editar(Prova: ProvasModel) {
    this.Prova = Prova;
  }

  Excluir(Prova: ProvasModel) {
    if (!confirm("Excluir Prova?")) {
      return;
    }

    this.provaService.removerProva(Prova.idprovas).then(resExcluir => {
      this.AtualizarProvas();
    });
  }

  AtualizarProvas() {
    this.campanhaService.getAllCampanhas().then(resCampanhas => {
      this.Campanhas = resCampanhas;
      this.Prova = new ProvasModel();
      this.provaService.getAllProvas().then(resProvas => {
        this.ListaProvas = resProvas;
        this.CreateProvasArray();
        this.SelectProvas(this.CampanhaEscolhida);
        //console.log(this.Provas);
        if(this.Provas.length == 1){
          this.ProvaAtualAtt = new ProvaAtualAttModel;
          for(let i = 0; i < this.Campanhas.length; i++){
            if(this.CampanhaEscolhida == this.Campanhas[i].idcampanhas){
              this.ProvaAtualAtt.datainicial = <any>`${this.Campanhas[i].datainicial.toString().split("T")[0]} ${this.Campanhas[i].datainicial.toString().split("T")[1].split(".")[0]}`;
              this.ProvaAtualAtt.datafinal = <any>`${this.Campanhas[i].datafinal.toString().split("T")[0]} ${this.Campanhas[i].datafinal.toString().split("T")[1].split(".")[0]}`;
              //console.log(this.ProvaAtualAtt.datainicial ,this.ProvaAtualAtt.datafinal);
            }
          }
          this.ProvaAtualAtt.idcampanhas = this.Provas[0].idcampanhas;
          this.ProvaAtualAtt.idprovas = this.Provas[0].idprovas;
          this.provaService.setProvaAtual(this.ProvaAtualAtt).then(resProvaAtual => {
            //console.log("set PA")
          });
        } else {
          //console.log("dont set")
        }
      });
    });
  }

}
