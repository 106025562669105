import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { LoginModel } from '../login/loginModel';
import { ConsultorModel } from './consultorModel';

@Injectable()
export class ConsultorService {

  Rota: String = this.globalFunc.APIUrl() + "/apicap";
  idconsultores: number = parseInt(localStorage.getItem("idconsultores"));

  constructor(
    private http: HttpClient,
    private globalFunc: GlobalFunctions
  ) { }

  cadastrarConsultor(formConsultor: ConsultorModel) {
    return this.http.post<ResponseModel>(`${this.Rota}/cadastrarConsultor`, formConsultor)
      .toPromise()
      .then(response => <ResponseModel>response)
      .then(data => { 
        return <ResponseModel>data;
      })
  }

  atualizarConsultor(formConsultor: ConsultorModel) {
    return this.http.patch<ResponseModel>(`${this.Rota}/atualizarConsultor`, formConsultor)
      .toPromise()
      .then(response => <ResponseModel>response)
      .then(data => { 
        return <ResponseModel>data;
      })
  }

  removerConsultor(idconsultores: number) {
    const response = this.http
      .delete(`${this.Rota}/removerConsultor/${idconsultores}`)
      .toPromise();
    return response;
  }

  getAllConsultores() {
    return this.http.get<ConsultorModel[]>(`${this.Rota}/getAllConsultores`)
      .toPromise()
      .then(response => <ConsultorModel[]>response)
      .then(data => { 
        return <ConsultorModel[]>data['response'];
      })
  }

  getAllConsultoresByCampanhas(idcampanhas: number) {
    return this.http.get<ConsultorModel[]>(`${this.Rota}/getAllConsultoresByCampanhas/${idcampanhas}`)
      .toPromise()
      .then(response => <ConsultorModel[]>response)
      .then(data => { 
        return <ConsultorModel[]>data['response'];
      })
  }

  getConsultoresEnabled() {
    return this.http.get<ConsultorModel[]>(`${this.Rota}/getConsultoresEnabled`)
      .toPromise()
      .then(response => <ConsultorModel[]>response)
      .then(data => { 
        return <ConsultorModel[]>data['response'];
      })
  }

  getConsultor(idconsultores: number) {
    return this.http.get<ConsultorModel>(`${this.Rota}/getConsultor/${idconsultores}`)
      .toPromise()
      .then(response => <ConsultorModel>response)
      .then(data => { 
        return <ConsultorModel>data['response'][0];
      })
  }

  Login(Form: LoginModel) {
    return this.http.post<ConsultorModel>(`${this.Rota}/login`, Form)
      .toPromise()
      .then(response => <ConsultorModel>response)
      .then(data => { 
        return <ConsultorModel>data['response'][0];
      })
  }

}
