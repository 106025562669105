import { Time } from '@angular/common';

export class ProvasModel {
    idprovas: number = null;
    idcampanhas: number = null;
    descricao: string = null;
    repontuar: boolean = false;
    habilitado: boolean = true;
    classificados: number = 0;
    senha: string;
    arquivo: File;
}

export class ProvaAtualAttModel{
    idprovas: number = null;
    idcampanhas: number = null;
    datainicial: Date;
    datafinal: Date;
}

export class PontuacaoModel {
    idpontuacao: number;
    idleads: number;
    idprovas: number;
    idcampanhas: number;
    pontuacao: number;
}

export class ProvaAtualModel {
    idprovaatual: number;
    idcampanhas: number;
    idprovas: number;
    datainicial: Date;
    datafinal: Date;
    habilitado: boolean;
    Notificacao: boolean;
}

export class ProvaAtualDadosModel {
    idprovaatual: number;
    idcampanhas: number;
    campanha: string;
    idprovas: number;
    prova: string;
    datainicial: Date;
    horainicial: Time;
    datafinal: Date;
    horafinal: Time;
    habilitado: boolean;
}

export class PlacarModel {
    posicao: number;
    idprovas: number;
    idcampanhas: number;
    prova: string;
    classificados: number;
    idleads: number;
    participante: string;
    pontuacao: number;
    data_participacao: Date;
    Cidade: string;
}

export class PlacarEscolasModel {
    unidade: string;
    data_cadastro: string;
    participantes: number;
    estado: string;
    cidade: string;
    idconsultores: number;
    idcampanhas: number;
    tipo_user: number;
}