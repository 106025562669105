import { Component, OnInit } from '@angular/core';
import { ResponseModel } from '../appModel';
import { CampanhaService } from './campanhaService';
import { CampanhasModel, EtapaModel } from './campanhasModel';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-campanhas',
  templateUrl: './campanhas.component.html',
  styleUrls: ['./campanhas.component.scss']
})
export class CampanhasComponent implements OnInit {

  Campanha: CampanhasModel = new CampanhasModel();
  ListaCampanhas: CampanhasModel[];
  txtInformativo: string = "";
  ColunasCampanhas: any[];
  HoraAbertura: string;
  HoraFechamento: string;
  CampanhasAtuais: CampanhasModel[];
  UUID: string;
  CreateEtapa: CampanhasModel = new CampanhasModel();
  Etapa: EtapaModel = new EtapaModel();

  constructor(
    private campanhaService: CampanhaService
  ) { }

  ngOnInit(): void {
    this.ColunasCampanhas = [
      { field: 'idcampanhas', header: 'ID' },
      { field: 'nome', header: 'Nome' },
      { field: 'descricao', header: 'Descrição' },
      { field: 'habilitado', header: 'Habilitado' },
      { field: 'datainicial', header: 'Data Inicial' },
      { field: 'datafinal', header: 'Data Final' },
    ];
    this.AtualizarLista();
  }

  AtualizarLista() {
    this.Campanha = new CampanhasModel();
    this.HoraAbertura = "";
    this.HoraFechamento = "";
    this.campanhaService.getAllCampanhas().then(resCampanhas => {
      this.ListaCampanhas = resCampanhas;
      this.CampanhasAtuais = resCampanhas;
      //console.log(this.CampanhasAtuais);
    })
  }

  Salvar() {
    this.Campanha.datafinal = <any>(this.Campanha.datafinal.toString().split("T")[0] + " " + this.HoraFechamento);
    this.Campanha.datainicial = <any>(this.Campanha.datainicial.toString().split("T")[0] + " " + this.HoraAbertura);
    if (this.Campanha.idcampanhas > 0) { // UPDATE
      this.campanhaService.atualizarCampanha(this.Campanha).then(resAtualizar => {
        this.FimCadastro(resAtualizar);
      });
    }
    else { // INSERT
      this.UUID = uuidv4();
      this.CreateUUID(this.UUID);
      this.Campanha.uuid = this.UUID;

      this.campanhaService.cadastrarCampanha(this.Campanha).then(resCadastro => {
        this.campanhaService.getAllCampanhas().then(resCampanhas => {
          this.CampanhasAtuais = resCampanhas;
          this.CheckUUID(this.UUID);

          this.Etapa.idcampanhas = this.CreateEtapa.idcampanhas;
          this.Etapa.habilitado = this.CreateEtapa.habilitado;
          //console.log(this.Etapa)
          this.campanhaService.criarEtapaAtual(this.Etapa).then(resCriar => {
          this.FimCadastro(resCadastro);
          });
        })

      });
    }
  }

  CreateUUID(uuid) {
    for(let i = 0; i < this.CampanhasAtuais.length; i++){
      if(this.CampanhasAtuais[i].uuid == uuid){
        this.UUID = uuidv4();
        return this.CreateUUID(this.UUID)
      }
    }
  }

  CheckUUID(uuid) {
    for(let i = 0; i < this.CampanhasAtuais.length; i++){
      if(this.CampanhasAtuais[i].uuid == uuid){
        this.CreateEtapa = this.CampanhasAtuais[i];
        //console.log(this.CreateEtapa);
      }
    }
  }

  FimCadastro(results: ResponseModel) {
    if (results.status == 200) {
      this.txtInformativo = "Sucesso!";
      setTimeout(() => {
        this.txtInformativo = "";
        this.AtualizarLista();
      }, 800);
    }
    else {
      this.txtInformativo = "Erro!";
      setTimeout(() => {
        this.txtInformativo = "";
      }, 5000);
    }
  }

  Editar(Campanha: CampanhasModel) {
    this.Campanha = Campanha;
    this.HoraAbertura = Campanha.datainicial.toString().split("T")[1].substring(0, 5);
    this.HoraFechamento = Campanha.datafinal.toString().split("T")[1].substring(0, 5);
    this.Campanha.datainicial = <any>(Campanha.datainicial.toString().split("T")[0]);
    this.Campanha.datafinal = <any>(Campanha.datafinal.toString().split("T")[0]);
  }

  Excluir(Campanha: CampanhasModel) {
    if (!confirm("Excluir Campanha?")) {
      return;
    }

    this.campanhaService.removerCampanha(Campanha.idcampanhas).then(resExcluir => {
      this.campanhaService.deletarProva(Campanha.idcampanhas).then(resDelete => {
        this.campanhaService.deletarProvaAtual(Campanha.idcampanhas).then(resRemove => {
          this.AtualizarLista();
        });
      });
    });
  }

}
