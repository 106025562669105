
<div class="container-fluid form-container">
    <div class="row">
        <div class="logo">
            <img src="../../assets/images/logo-rob.png" alt="" class="img-fluid">
        </div>
    </div>
    <div class="row col-md-8 col-sm-10 form" style="margin: auto;">
        <div class="col-md-12">
            <div class="campanha-nome col-12">
                <span style="font-weight: bold;">LOG IN</span>
            </div>
            <form>
                <div class="col-md-12">
                    <label>Usuário</label>
                    <input type="text" class="form-control" placeholder="(00) 00000-0000" [(ngModel)]="Usuario.telefone" [ngModelOptions]="{standalone: true}" [dropSpecialCharacters]="false" mask="(00) 00000-0000">
                </div>
                <div class="col-md-12">
                    <label>Senha</label>
                    <input type="password" class="form-control" placeholder="Senha" [(ngModel)]="Usuario.senha"[ngModelOptions]="{standalone: true}">
                </div>
                <br>
                <div class="col-md-12">
                    <label *ngIf="txtInformativo != ''">{{txtInformativo}}</label>
                </div>
                <div class="col-md-12 text-center" style="margin-top: 1em;">
                   <!-- <button type="button" class="btn btn-inscrição" (click)="Cadastrar()">Cadastre-se</button>-->
                    <button type="submit" class="btn btn-inscrição"  (click)="Login()">Entrar</button>
                </div>
            </form>
        </div>
    </div>
</div>