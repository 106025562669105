<div class="row col-md-12">
  <div class="col-md-6">
    <label>Selecione a campanha</label>
    <select [(ngModel)]="CampanhaEscolhida" name="data" id="selectCampanha" class="form-control" (ngModelChange)="SelectCampanha($event)">
      <option value="" selected disabled></option>
      <option *ngFor="let Option of Campanhas; i as index" [ngValue]="Option.idcampanhas">{{Option.descricao}}</option>
    </select>
  </div>
  <hr style="height: 10px;width: 100%;color: #000;">
  <br>
</div>

<div *ngIf="EditarProvas" class="row col-md-12">
  <div class="container-fluid row" style="margin-bottom: 1rem;">
    <div class="col-md-5">
      <label>Campanha</label>
      <input type="text" class="form-control" [(ngModel)]="CampanhaTitulo" readonly>
    </div>
    <div class="col-md-2">
      <label>ID Campanha</label>
      <input type="text" class="form-control" [(ngModel)]="CampanhaEscolhida" readonly>
    </div>
  </div>
  <div class="col-md-2">
    <label>Descrição</label>
    <input type="text" class="form-control" placeholder="descrição da prova" [(ngModel)]="Prova.descricao">
  </div>
  <div class="col-md-2">
    <label>Senha</label>
    <input type="text" class="form-control" placeholder="senha próxima etapa" [(ngModel)]="Prova.senha">
  </div>
  <div class="col-md-2">
    <label>Classificados</label>
    <input type="number" class="form-control" [(ngModel)]="Prova.classificados">
  </div>
  <div class="col-md-2">
    <label>Repontuar</label>
    <input type="checkbox" class="form-control" [(ngModel)]="Prova.repontuar">
  </div>
  <div class="col-md-2">
    <label>Habilitado</label>
    <input type="checkbox" class="form-control" [(ngModel)]="Prova.habilitado">
  </div>
  <div class="col-md-2">
    <label>Arquivo</label>
    <input type="file" class="form-control" (change)="onFileChanged($event)">
  </div>
  <div class="col-md-12">
    <label *ngIf="txtInformativo != ''">{{txtInformativo}}</label>
  </div>
  <div class="col-md-12" style="margin-top: 1em;">
    <button class="btn btn-success" (click)="Salvar()">Salvar</button>
  </div>

  <div style="margin-top: 2em; border-top: 1px solid;">
    <p-table #dt [columns]="ColunasProvas" [value]="Provas" [paginator]="true" [rows]="10" [responsive]="true">
      <ng-template pTemplate="caption" class="col-sm-4">
        <div style="text-align: right">
          <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
          <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt.filterGlobal($event.target.value, 'contains')">
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
            {{col.header}}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
          <th>Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            <span *ngIf="col.header.includes('Data')">{{rowData[col.field] | date: 'dd/MM/yyyy HH:mm'}}</span>
            <span *ngIf="!col.header.includes('Data')">{{rowData[col.field]}}</span>
          </td>
          <td>
            <button class="btn btn-warning" (click)="Editar(rowData)">Editar</button>
            <button class="btn btn-danger float-right" (click)="Excluir(rowData)">Excluir</button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>