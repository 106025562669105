<div class="container-fluid tabela">
    <div class="col-12 col-md-6 mx-auto ranking-hero text-center">RANKING DO CAMPEONATO</div>
    <div class="container">
        <table class="table">
            <thead>
                <th scope="col">#</th>
                <th scope="col">Nome</th>
                <th scope="col">Pontos</th>
            </thead>
            <tbody>
                <tr *ngFor="let ranking of Ranking; let i = index">
                    <th scope="row">{{i + 1}}</th>
                    <td>
                        <strong class="tdClass">{{ranking.participante}}</strong><br>
                        {{ranking.escola}}
                    </td>
                    <td>{{ranking.pontuacao}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>