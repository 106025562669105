import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { ConsultorModel, ParticipanteModel } from './interessesModel';

@Injectable()
export class LandingService {

    Rota: String = this.globalFunc.APIUrl() + "/apicap";

    constructor(
      private http: HttpClient,
      private globalFunc: GlobalFunctions
    ) { }

    cadastrarParticipante(formLead: ParticipanteModel) {
      return this.http.post<ResponseModel>(`${this.Rota}/cadastrarintparticipante`, formLead)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    cadastrarConsultor(formLead: ConsultorModel) {
        return this.http.post<ResponseModel>(`${this.Rota}/cadastrarintconsultor`, formLead)
          .toPromise()
          .then(response => <ResponseModel>response)
          .then(data => {
            return <ResponseModel>data;
          })
      }
}
