import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { LeadsModel, ParticipacoesModel } from './leadsModel';

@Injectable()
export class LeadService {

    Rota: String = this.globalFunc.APIUrl() + "/apicap";
    idconsultores: number = parseInt(localStorage.getItem("idconsultores"));

    constructor(
      private http: HttpClient,
      private globalFunc: GlobalFunctions
    ) { }

    cadastrarLead(formLead: LeadsModel) {
      return this.http.post<ResponseModel>(`${this.Rota}/cadastrarLead`, formLead)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    atualizarLead(formConsultor: LeadsModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/atualizarLead`, formConsultor)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    removerLead(idleads: number) {
      const response = this.http
        .delete(`${this.Rota}/removerLead/${idleads}`)
        .toPromise();
      return response;
    }

    getAllLeads() {
      return this.http.get<LeadsModel[]>(`${this.Rota}/getAllLeads`)
        .toPromise()
        .then(response => <LeadsModel[]>response)
        .then(data => {
          return <LeadsModel[]>data['response'];
        })
    }

    getAllLeadsConsultor(idcampanhas: number) {
      return this.http.get<LeadsModel[]>(`${this.Rota}/getAllLeadsConsultor/${this.idconsultores}/${idcampanhas}`)
        .toPromise()
        .then(response => <LeadsModel[]>response)
        .then(data => {
          return <LeadsModel[]>data['response'];
        })
    }

    getLead(idleads: number) {
      return this.http.get<LeadsModel>(`${this.Rota}/getLead/${idleads}`)
        .toPromise()
        .then(response => <LeadsModel>response)
        .then(data => {
          return <LeadsModel>data['response'][0];
        })
    }

    getLeadsCampanha(idcampanhas: number) {
      return this.http.get<LeadsModel[]>(`${this.Rota}/getLeadsCampanha/${idcampanhas}`)
        .toPromise()
        .then(response => <LeadsModel[]>response)
        .then(data => {
          return <LeadsModel[]>data['response'];
        })
    }

    salvarObsLead(formConsultor: LeadsModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/salvarObsLead`, formConsultor)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    getParticipacaoNaProva(idleads: number, idprovas: number, idcampanhas: number) {
      return this.http.get<ParticipacoesModel[]>(`${this.Rota}/getParticipacaoNaProva/${idleads}/${idprovas}/${idcampanhas}`)
        .toPromise()
        .then(response => <ParticipacoesModel[]>response)
        .then(data => {
          return <ParticipacoesModel[]>data['response'];
        })
    }

    getClassificados(idcampanhas: number, idconsultores: number) {
      return this.http.get<LeadsModel[]>(`${this.Rota}/getClassificados/${idcampanhas}/${idconsultores}`)
        .toPromise()
        .then(response => <LeadsModel[]>response)
        .then(data => {
          return <LeadsModel[]>data['response'];
        })
    }

}
