import { LeadsModel } from '../captacao/leadsModel';
import { ProvasModel } from '../provas/provasModel';

export class CampanhasModel {
    idcampanhas: number;
    nome: string;
    descricao: string;
    habilitado: boolean;
    datainicial: Date;
    datafinal: Date;
    uuid: string;
}

export class EtapaModel {
    idcampanhas: number;
    habilitado: boolean;
}

export class CampanhasLeads {
    Campanha: CampanhasModel;
    Leads: LeadsModel[];
    LeadsParticipou: LeadsModel[] = new Array<LeadsModel>();
    LinkCaptacao: string;
    LinkCadastroConsultores: string = '';
    Provas: ProvasModel[];
    LeadsConfirmados: number;
}

export class CampanhasLeadsResults {
    idcamp: number;
    nomec: string;
    descricao: string;
    habilitado: boolean;
    datainicial: Date;
    datafinal: Date;
    idleads: number;
    nome: string;
    senha: string;
    estado: string;
    cidade: string;
    telefone: string;
    email: string;
    datanascimento: Date;
    observacao: string;
    idcampanhas: number;
    idconsultores: number;
    confirmado: boolean;
    insert_date: Date;
    idpontuacao: number;
    idprovas: number;
    prova: string;
    pontuacao: number;
    data_participacao: Date;
    unidade: string;
}