import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject , ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalFunctions } from 'src/environments/environment';
import { CampanhaService } from '../campanhas/campanhaService';
import { CampanhasModel } from '../campanhas/campanhasModel';
import { CidadesModel, EstadosModel } from '../compartilhado/compartilhadoModel';
import { CompartilhadoService } from '../compartilhado/compartilhadoService';
import { ConsultorModel } from './consultorModel';
import { ConsultorService } from './consultorService';

@Component({
  selector: 'app-cad-usuario',
  templateUrl: './cad-usuario.component.html',
  styleUrls: ['./cad-usuario.component.scss']
})
export class CadUsuarioComponent implements OnInit {

  Campanha: CampanhasModel = new CampanhasModel();
  DataExpirou: boolean = false;
  DataAntecipada: boolean = false;

  Consultor: ConsultorModel = new ConsultorModel();
  txtInformativo: string = "";
  txtConfirmaSenha: string = "";
  ListaConsultores: ConsultorModel[];
  ListaEstados: EstadosModel[];
  ListaCidades: CidadesModel[];
  CidadesEstado: CidadesModel[];

  constructor(
    private campanhaService: CampanhaService,
    private router: Router,
    private route: ActivatedRoute,
    private consultorService: ConsultorService,
    private compartilhadoService: CompartilhadoService,
    private globalFunc: GlobalFunctions,
    @Inject(DOCUMENT) private document,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    let dados = parseInt(this.route.snapshot.params['dados']);

    this.GetCampanhas(dados)
    this.consultorService.getAllConsultoresByCampanhas(dados).then(resConsultores => {
      this.ListaConsultores = resConsultores;

      this.compartilhadoService.LerJson("assets/Estados.json").then(resEstados => {
        this.ListaEstados = resEstados;
        this.compartilhadoService.LerJson("assets/Cidades.json").then(resCidades => {
          this.ListaCidades = resCidades;

        });
      });
 

      this.Consultor.idcampanhas = dados;
      this.Consultor.tipo_user = 0;
    });

    this.AjustarContainer();

    this.globalFunc.chamaScript(this.document, this.elementRef, "lottieScript.js");
    
  }
   GetCampanhas(dados){
     this.campanhaService.getCampanha(dados).then(resCampanha => {
      this.Campanha = resCampanha;

      let DataAtual: Date = new Date();

      if (DataAtual > new Date(this.Campanha.datafinal)) {
        this.DataExpirou = true;
        return;
      }
      else if (DataAtual < new Date(this.Campanha.datainicial)) {
        this.DataAntecipada = true;
        return;
      }
      else if (!this.Campanha.habilitado) {
        return;
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  GetIdEstado() {
    let IDEstado: number = 0;
    for (let i = 0; i < this.ListaEstados.length; i++) {
      if (this.ListaEstados[i].Sigla == this.Consultor.estado) {
        IDEstado = this.ListaEstados[i].ID;
        break;
      }
    }
    this.CarregarCidades(IDEstado);
  }

  CarregarCidades(IDEstado) {
    this.CidadesEstado = new Array<CidadesModel>();
    for (let i = 0; i < this.ListaCidades.length; i++) {
      if (this.ListaCidades[i].Estado == IDEstado) {
        this.CidadesEstado.push(this.ListaCidades[i]);
      }
    }
  }

  Cadastrar() {
    let Erro: string = this.globalFunc.VerificaCaractereNoCampo(this.Consultor, ["", null], ["idconsultores", "insert_date", "update_date"]);
    if (Erro) {
      this.txtInformativo = "Campos obrigatórios sem preencher: " + Erro;
      return;
    }

    for (let i = 0; i < this.ListaConsultores.length; i++) {
      if (this.ListaConsultores[i].telefone == this.Consultor.telefone) {
        this.txtInformativo = "Este TELEFONE já está cadastrado!";
        return;
      }
      else if (this.ListaConsultores[i].email == this.Consultor.email) {
        this.txtInformativo = "Este EMAIL já está cadastrado!";
        return;
      }
    }

    if (this.Consultor.senha != this.txtConfirmaSenha) {
      this.txtInformativo = "As senhas não conhecidem";
      setTimeout(() => {
        this.txtInformativo = "";
      }, 5000);
      return;
    }

    this.consultorService.cadastrarConsultor(this.Consultor).then(resCadastro => {
      if (resCadastro.status == 200) {
        this.txtInformativo = "Cadastramento realizado com sucesso!";
        setTimeout(() => {
          this.Login();
        }, 800);
      }
      else {
        this.txtInformativo = "Erro ao cadastrar consultor";
        if (resCadastro.error.sqlMessage.includes("Duplicate entry")) {
          this.txtInformativo = "Número de telefone já cadastrado!";
        }
        setTimeout(() => {
          this.txtInformativo = "";
        }, 5000);
      }
    })
  }

  Login() {
    window.location.href = window.location.href.split('/')[0] + "/login";
    //window.location.reload();
    //this.router.navigate(['login']);
  }

  AjustarContainer() {
    let appSelect = document.querySelector("app-cad-usuario");
    appSelect['style'].height = "100%";
  }

  OpenTermos() {
    const modal = document.querySelector('.termos-modal');
    const box = document.querySelector('.termos-box');

    modal['style'].pointerEvents = "visible";

    modal['style'].display = "flex";
    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)";

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(1)"
            },400)
        },500)
    },0)
  }

  CloseTermos(){
    const modal = document.querySelector('.termos-modal');
    const box = document.querySelector('.termos-box');

    modal['style'].pointerEvents = "none";

    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)"

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(0)"
                setTimeout(() => {
                    modal['style'].backgroundColor = "rgba(39, 39, 39, 0)";
                },100)
            },400)
        },200)
    },0)
  }

}
