<!--FORMULARIO DE CADASTRO-->
<div class="container-fluid form-container">
  <div class="campanha-nome">
    <span style="font-weight: bold">{{ Campanha.nome }}</span>
  </div>
  <div
    class="animation-container"
    *ngIf="!NotFound && !SucessoCadastro && !DataExpirou && !DataAntecipada"
  ></div>
  <div
    class="row col-md-8 col-xl-6 form"
    *ngIf="
      !NotFound &&
      !SucessoCadastro &&
      !DataExpirou &&
      !DataAntecipada &&
      Campanha.habilitado
    "
  >
    <div class="row col-md-12" style="margin: auto">
      <div class="inscrição text-center">Inscrição de participante</div>
      <div class="col-md-12">
        <label>Nome</label>
        <input
          type="text"
          class="form-control"
          placeholder="Nome"
          [(ngModel)]="Lead.nome"
        />
      </div>
      <div class="col-md-6">
        <label>Estado</label>
        <select
          class="form-control"
          [(ngModel)]="Lead.estado"
          (change)="GetIdEstado()"
        >
          <option value="" selected disabled>* Selecione o estado</option>
          <option *ngFor="let Estado of ListaEstados" [value]="Estado.Sigla">
            {{ Estado.Nome }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label>Cidade</label>
        <select class="form-control" [(ngModel)]="Lead.cidade">
          <option value="" selected disabled>* Selecione a cidade</option>
          <option *ngFor="let Cidade of CidadesEstado" [value]="Cidade.Nome">
            {{ Cidade.Nome }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label>Telefone</label>
        <input
          type="text"
          class="form-control"
          placeholder="(00) 00000-0000"
          [(ngModel)]="Lead.telefone"
          [dropSpecialCharacters]="false"
          mask="(00) 00000-0000"
        />
      </div>
      <div class="col-md-6">
        <label>Data de Nascimento</label>
        <input
          type="date"
          class="form-control"
          [(ngModel)]="Lead.datanascimento"
        />
      </div>
      <div class="col-md-12">
        <label>E-mail</label>
        <input
          type="email"
          class="form-control"
          placeholder="seu@email.com"
          [(ngModel)]="Lead.email"
        />
      </div>
      <div class="col-md-12">
        <div
          style="
            color: #fff;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
          "
        >
          <input
            type="checkbox"
            style="margin-top: 0.3rem; margin-left: 1rem"
            [(ngModel)]="Lead.aceito"
          />
          <label for="" style="margin-left: 0.3rem"
            >Eu li e aceito os
            <span class="termos" (click)="OpenTermos()"
              >Termos de Uso e Política de Privacidade</span
            ></label
          >
        </div>
      </div>
      <div class="col-md-12" style="margin-top: 1em">
        <label *ngIf="txtInformativo != ''">{{ txtInformativo }}</label>
      </div>
      <div
        class="col-md-12"
        style="margin-top: 1em; display: flex; justify-content: center"
      >
        <button class="btn btn-inscrição" (click)="Cadastrar()">
          Inscrever-se
        </button>
      </div>
    </div>
  </div>

  <!--SUCESSO AO CADASTRAR-->
  <div class="message-container" *ngIf="SucessoCadastro">
    <div class="message-txt">
      <strong style="color: #1b70ec">Cadastro realizado com sucesso!</strong>
      <br /><br />
      <strong>Confirme seu cadastro em seu email!</strong><br />
      Sua chave de acesso para participar da primeira etapa do evento até do dia
      06/11/2022 é:
      <span style="font-weight: bold; color: #1b70ec !important">{{
        txtInformativo
      }}</span
      ><br /><br />
      <strong>Para participar é bem simples!</strong>

      <br />
      Baixe o aplicativo do <strong>Campeonato de Robótica</strong> na Google
      Play Store, utilize sua <strong> chave de acesso</strong> e participe nas
      datas estabelecidas para cada etapa!
    </div>
  </div>

  <!--LINK INVALIDO-->
  <div class="message-container" *ngIf="NotFound">
    <h2>Página não encontrada</h2>
  </div>

  <!--ACESSO ANNTES DA DATA DE ABERTURA DAS INSCRICOES-->
  <div class="message-container" *ngIf="DataAntecipada">
    <h2>
      As inscrições para
      <span style="font-weight: bold">{{ Campanha.nome }}</span> terão início em
      {{ Campanha.datainicial | date: "dd/MM/yyyy HH:mm" }}
    </h2>
  </div>

  <!--ACESSO APOS DATA DE ENCERRAMENTO DAS INSCRICOES-->
  <div class="message-container" *ngIf="DataExpirou">
    <h2>
      As inscrições para
      <span style="font-weight: bold">{{ Campanha.nome }}</span> encerraram em
      {{ Campanha.datafinal | date: "dd/MM/yyyy HH:mm" }}
    </h2>
  </div>

  <!--CAMPANHA DESABILITADA-->
  <div class="message-container" *ngIf="!Campanha.habilitado">
    <h2>Esse evento foi desativado</h2>
  </div>
</div>

<div class="termos-modal">
  <div class="termos-box">
    <div class="row-close-term">
      <div class="closeterm-btn" (click)="CloseTermos()">X</div>
    </div>
    <div class="text-termos">
      <strong>Termos de Uso e Política de Privacidade </strong> <br /><br />

      <strong>1.</strong> O presente Termo de Uso e Política de Privacidade
      possui como objetivo reafirmar e esclarecer os compromissos da Maker
      Robotics, bem como fixar as condições de adesão e acesso ao Campeonato de
      Robótica disponibilizado pela Maker Robotics em relação ao seu cliente,
      fornecedor e/ou consulente (“Usuário”). <br /><br />
      <strong>2.</strong> Este Termo de Uso e Política de Privacidade regula as
      disposições gerais em relação ao uso do Campeonato de Robótica
      disponibilizado pela Maker Robotics, dessa forma o serviço fornecido terá
      suas regras, condições e valores que serão fixados pelo contrato principal
      e/ou demais ordens de serviço. <br /><br />
      <strong>3.</strong> A Maker Robotics emprega as melhores práticas de
      segurança e privacidade, visando garantir a confidencialidade das
      informações conforme previsto em lei. <br /><br />
      <strong>4.</strong> É de responsabilidade do Usuário a adoção de medidas
      de segurança razoáveis nos dispositivos que utiliza para acessar o
      Campeonato de Robótica disponibilizado pela Maker Robotics. <br /><br />
      <strong>5.</strong> A coleta, o tratamento, o armazenamento e a
      disponibilização dos dados, inclusive sensíveis, e das informações e dados
      fornecidos pelo Usuário ocorrerá sempre dentro dos termos da legislação
      brasileira vigente, em especial às Leis 12.965 de 2014 e 13.709 de 2018,
      para fins de proteção do crédito, garantia da prevenção à fraude e à
      segurança do titular, nos processos de identificação e autenticação de
      cadastro em sistemas eletrônicos. <br /><br />
      <strong>6.</strong> Ao realizar o cadastro e concordar com o Termo de Uso
      e Política de Privacidade, o usuário possui ciência, concorda e autoriza
      com à coleta, tratamento e o armazenamento de seus dados e dos demais
      dados pessoais que fornece ao se cadastrar no Campeonato de Robótica
      disponibilizado pela Maker Robotics, no momento de cadastro e ao decorrer
      do uso. <br /><br />
      <strong>7.</strong> O Usuário garante que informará apenas dados corretos
      e que obteve de forma lícita ou mediante autorização, nos casos em que
      essa foi necessária. <br /><br />
      <strong>8.</strong> Cada Usuário receberá uma senha e um logon que são
      exclusivos e intransferíveis. É de responsabilidade exclusiva do Usuário o
      zelo e sigilo sobre suas informações de logon e senha. <br /><br />
      <strong>9.</strong> No caso de dados inexatos, incompletos ou
      desatualizados, o Usuário possui a ciência de que estará sujeito a
      suspensão do acesso de algumas funções, serviços fornecidos e/ou
      cancelamento pela Maker Robotics de cadastro no Campeonato de Robótica
      disponibilizado pela Maker Robotics, independente de notificação.
      <br /><br />
      <strong>10.</strong> A adesão e o acesso virtual ao Campeonato de Robótica
      disponibilizado pela Maker Robotics ocorrerá de forma gratuita, e o
      Usuário estará isento de qualquer tipo de cobrança de valores em
      decorrência da utilização de serviços, conforme estabelecido nas condições
      presentes no Campeonato de Robótica disponibilizado pela Maker Robotics,
      Ordens de Serviço e demais fatores. <br /><br />
      <strong>11.</strong> O Usuário autoriza a utilização dos dados de e-mail,
      telefone (ligações e/ou mensagens de texto) e endereço para a comunicação
      da Maker Robotics, como por exemplo, mas se se restringir, a comunicações
      acerca da alteração deste Termo de Uso e Política de Privacidade e
      propagandas de serviços fornecidos. Caso o Usuário não queira mais receber
      os comunicados da Maker Robotics, poderá solicitar o cancelamento de tais
      envios por meio do e-mail vendas2@makereducacao.com.br disponibilizado
      pela Maker Robotics. <br /><br />
      <strong>12.</strong> É de total responsabilidade do Usuário a utilização
      das informações a que teve acesso em decorrência da utilização do
      Campeonato de Robótica disponibilizado pela Maker Robotics, assegurando
      sempre que serão utilizadas de forma devida e com respaldo em lei. Em caso
      de desrespeito ao previsto nesta cláusula, o Usuário responderá
      individualmente por eventuais utilizações indevidas. <br /><br />
      <strong>13.</strong> A responsável pela coleta, tratamento e armazenamento
      das informações e dados é a MAKER ROBOTICA E TECNOLOGIA LTDA., inscrita no
      CNPJ/MF sob o nº 26.465.547/0001-56 , que é a detentora da marca Maker
      Robotics, que se encontra localizado na Rua Joaquiem de Góes, 584, CEP
      13610-108, Centro – Leme-SP. <br /><br />
      <strong>14.</strong> O Usuário é exclusivamente responsável pela exatidão
      e veracidade dos dados que forneceu, isentando a Maker Robotics em caso de
      erros ou danos que resultem destas informações errôneas. <br /><br />
      <strong>15.</strong> É responsabilidade do Usuário a constante atualização
      das informações e dados informados e/ou fornecidos a Maker Robotics.
      <br /><br />
      <strong>16.</strong> É possível que o site da Maker Robotics contenha
      links e acessos seguros que redirecionem o Usuário a sites externos,
      entretanto este Termos de Uso e a Política de Privacidade faz referência
      apenas a utilização do Campeonato de Robótica disponibilizado pela Maker
      Robotics. Dessa forma, é de responsabilidade do Usuário consultar as
      políticas de privacidade de outros sites. <br /><br />
      <strong>17.</strong> Ao aceitar este Termo de Uso e a Política de
      Privacidade, o Usuário concorda com os cookies de navegação e
      identificação do IP (Internet Protocol) no Campeonato de Robótica
      disponibilizado pela Maker Robotics, tais dados serão utilizados para
      melhoria de segurança, comunicação institucional e/ou comunicação
      comercial entre Maker Robotics e Usuário. <br /><br />
      <strong>18.</strong> O usuário poderá entrar em contato com a Maker
      Robotics através do Canal de Atendimento ao Cliente, utilizando o site da
      Maker Robotics (www.makerrobotics.com.br) por meio da assistência virtual
      ou através do e-mail vendas2@makereducacao.com.br. <br /><br />
      <strong>19.</strong> A Maker Robotics sempre entrará em contato com o
      Usuário pelos meios de comunicação oficiais. Em caso de desconfiança sobre
      um e-mail, ligação ou mensagem, o usuário deverá entrar em contato com a
      Maker Robotics através dos Canais de Atendimento acima especificados.
      <br /><br />
      <strong>20.</strong> A Maker Robotics não possui, em seus meios de
      comunicação, links que remetem a outras páginas na internet. Todas as
      informações devem estar disponíveis no próprio meio de comunicação ou seu
      site. <br /><br />
      <strong>21.</strong> A Maker Robotics assegura e garante o respeito aos
      direitos à privacidade, à proteção de dados pessoais e ao sigilo das
      comunicações privadas e dos registros. <br /><br />
      <strong>22.</strong> Todos os serviços, dados e demais fatores
      disponibilizados no Campeonato de Robótica disponibilizado pela Maker
      Robotics são protegidos por direitos autorais. É vedada a cópia com
      finalidade comercial deste Termo de Uso e Política de Privacidade ou
      alteração, modificação e/ou omissão das informações e/ou dados
      disponibilizados no Campeonato de Robótica disponibilizado pela Maker
      Robotics, bem como é vedada a reprodução de páginas. <br /><br />
      Ao selecionar o item “Li e Aceito” o usuário concorda com todas as
      disposições presentes neste Termo de Uso e Política de Privacidade para a
      navegação em qualquer Portal de Serviços disponibilizado pela Maker
      Robotics e utilização de seus produtos e serviços.
    </div>
  </div>
</div>
