<div class="full">
  <div class="Header">
  <ul>
    <li><span (click)="MudarAba(1)">Visão Geral</span></li>
    <li *ngIf="ModoAdministrador && ModoOrganizador"><span (click)="MudarAba(2)">Campanhas</span></li>
    <li *ngIf="ModoAdministrador && ModoOrganizador"><span (click)="MudarAba(3)">Provas</span></li>
    <li *ngIf="ModoAdministrador && ModoOrganizador"><span (click)="MudarAba(4)">Prova Atual</span></li>
    <li><span (click)="MudarAba(5)">Downloads</span></li>
    <li style="float:right"><span class="active" (click)="Sair()">Sair</span></li>
  </ul>
</div>
<div *ngIf="Carregado" class="ConteudoGeral">
  <div class="HeadComponent">
    {{txtHeader}}
  </div>
  <div class="BodyComponent">

    <!--VISAO GERAL DASHBOARD-->
    <div *ngIf="AbaAtual == 1">
      <div *ngFor="let Campanha of ListaCampanhas; let i = index">
        <label><span style="font-weight: bold;">{{Campanha.Campanha.nome}}</span> - Inscrições de {{Campanha.Campanha.datainicial | date: 'dd/MM/yyyy HH:mm'}} até {{Campanha.Campanha.datafinal | date: 'dd/MM/yyyy HH:mm'}}</label>
        <br> <br>
        <label *ngIf="ModoOrganizador" style="font-weight: bold;">Link para cadastro de escolas</label>
        <div *ngIf="ModoOrganizador" class="row col-md-12" style="margin-bottom: 1em;">
          <div class="col-md-8">
            <input #txtLink type="text" readonly class="form-control" [(ngModel)]="Campanha.LinkCadastroConsultores">
          </div>
          <div class="col-md-2">
            <button class="btn btn-success" (click)="CopiarLink(txtLink)">Copiar</button>
            <a [href]="Campanha.LinkCadastroConsultores" target="_blank"><button class="btn btn-success" style="float: right;">Acessar</button></a>
          </div>
          <div class="col-md-2" style="margin-top: -1em;">
            <qrcode [qrdata]="Campanha.LinkCadastroConsultores" [size]="200" [level]="'M'" [id]="'qr_' + 0"></qrcode>
          </div>
        </div>
        <br>
        <label *ngIf="!ModoOrganizador || ModoAdministrador">Link para cadastro de competidores</label>
        <br>
        <div *ngIf="!ModoOrganizador || ModoAdministrador" class="row col-md-12" style="margin-bottom: 1em;">
          <div class="col-md-8">
            <input #txtLink type="text" readonly class="form-control" [(ngModel)]="Campanha.LinkCaptacao">
          </div>
          <div class="col-md-2">
            <button class="btn btn-success" (click)="CopiarLink(txtLink)">Copiar</button>
            <a [href]="Campanha.LinkCaptacao" target="_blank"><button class="btn btn-success" style="float: right;">Acessar</button></a>
          </div>
          <div class="col-md-2" style="margin-top: -1em;">
            <qrcode [qrdata]="Campanha.LinkCaptacao" [size]="200" [level]="'M'" [id]="'qr_' + 0"></qrcode>
          </div>
        </div>
        <br>
        <div class="row col-md-12" style="margin-bottom: 1em;">
          <div *ngIf="ModoOrganizador" class="col-md-2">
            <label style="font-weight: bold;">Total Consultores</label>
            <input type="text" class="form-control" readonly [(ngModel)]="ConsultoresSelecionados[i].length">
          </div>
          <div class="col-md-2">
            <label style="font-weight: bold;">Total Leads</label>
            <input type="text" class="form-control" readonly [(ngModel)]="Campanha.Leads.length">
          </div>
          <div class="col-md-2">
            <label style="font-weight: bold;">Leads Confirmados</label>
            <input type="text" class="form-control" readonly [(ngModel)]="Campanha.LeadsConfirmados">
          </div>
          <div class="col-md-2">
            <label style="font-weight: bold;">Participações</label>
            <input type="text" class="form-control" readonly [(ngModel)]="ListaPlacar.length">
          </div>
        </div>
        <label style="font-weight: bold;">Lista de leads alcançados</label> <br>
        <div class="row col-md-12" style="text-align: center;">
          <div class="col-md-3" style="border: 1px solid #eee;">Inscrito</div>
          <div class="col-md-3" style="background: lightgreen; border: 1px solid #eee;">Confirmado</div>
          <div class="col-md-3" style="background: lightblue; border: 1px solid #eee;">Participou</div>
        </div>
        <br>

        <p-table #dt [columns]="ColunasLeads" [value]="Campanha.Leads" [paginator]="true" [rows]="10" [responsive]="true">
          <ng-template pTemplate="caption" class="col-sm-4">
            <div style="float: left;">
              <button class="btn btn-primary" (click)="Imprimir(Campanha.Leads)">Imprimir</button>
              <button class="btn btn-success" (click)="Exportar(Campanha.Leads, 'Leads')" style="margin-left: 1em;">Exportar</button>
            </div>
            <div style="text-align: right">
              <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
              <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt.filterGlobal($event.target.value, 'contains')">
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width: 5em;">Obs</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pSelectableRow]="rowData" [ngStyle]="(rowData.Participacoes.length > 0 && {'background' : 'lightblue'}) || (rowData.confirmado && {'background' : 'lightgreen'})"> <!-- style="border-bottom: 1px solid #aaa;" [ngStyle]="(index % 2 == 0) && {'background' : '#eee'}"-->
              <td *ngFor="let col of columns" style="word-break: break-all">
                <span *ngIf="col.header == 'Data captação'">{{rowData[col.field] | date: 'dd/MM/yyyy HH:mm'}}</span>
                <span *ngIf="col.header == 'Data de Nascimento'">{{rowData[col.field] | date: 'dd/MM/yyyy'}}</span>
                <span *ngIf="!col.header.includes('Data')">{{rowData[col.field]}}</span>
              </td>
              <td>
                <button class="btn btn-primary" (click)="VerObservacoes(rowData)">Ver</button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <br>

        <label style="font-weight: bold;font-size: 20px;">Classificados - {{(ProvaAtual[i] == undefined) ? "" : ProvaAtual[i].prova}}</label> 
        <br>
        <br>

        <p-table #dt7 [columns]="ColunasLeads" [value]="Campanha.LeadsParticipou" [paginator]="true" [rows]="10" [responsive]="true">
          <ng-template pTemplate="caption" class="col-sm-4">
            <div style="float: left;">
              <button class="btn btn-primary" (click)="Imprimir(Campanha.LeadsParticipou)">Imprimir</button>
              <button class="btn btn-success" (click)="Exportar(Campanha.LeadsParticipou, 'Classificados')" style="margin-left: 1em;">Exportar</button>
            </div>
            <div style="text-align: right">
              <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
              <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt7.filterGlobal($event.target.value, 'contains')">
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width: 5em;">Obs</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pSelectableRow]="rowData" class="classEscola"> <!-- style="border-bottom: 1px solid #aaa;" [ngStyle]="(index % 2 == 0) && {'background' : '#eee'}"-->
              <td *ngFor="let col of columns" style="word-break: break-all">
                <span *ngIf="col.header == 'Data captação'">{{rowData[col.field] | date: 'dd/MM/yyyy HH:mm'}}</span>
                <span *ngIf="col.header == 'Data de Nascimento'">{{rowData[col.field] | date: 'dd/MM/yyyy'}}</span>
                <span *ngIf="!col.header.includes('Data')">{{rowData[col.field]}}</span>
              </td>
              <td>
                <button class="btn btn-primary" (click)="VerObservacoes(rowData)">Ver</button>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div *ngIf="!ModoOrganizador">
          <hr style="width: 50%;">

          <label>Placar na minha unidade</label> <br>
          <p-table #dt2 [columns]="ColunasPlacar" [value]="ListaPlacar" [paginator]="true" [rows]="10" [responsive]="true">
            <ng-template pTemplate="caption" class="col-sm-4">
              <div style="text-align: right">
                <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
                <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt2.filterGlobal($event.target.value, 'contains')">
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
              <tr [pSelectableRow]="rowData" [ngStyle]="rowData.confirmado && {'background' : 'lightgreen'}">
                <td *ngFor="let col of columns">
                  <span *ngIf="col.header.includes('Data')">{{rowData[col.field] | date: 'dd/MM/yyyy HH:mm'}}</span>
                  <span *ngIf="!col.header.includes('Data')">{{rowData[col.field]}}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>



        <hr style="width: 50%;">

        <label>Placar Geral</label> <br>
        <p-table #dt3 [columns]="ColunasPlacar" [value]="ListaPlacarGeral[i]" [paginator]="true" [rows]="10" [responsive]="true">
          <ng-template pTemplate="caption" class="col-sm-4">
            <div style="float: left;">
              <button class="btn btn-primary" (click)="Imprimir(ListaPlacarGeral[i])">Imprimir</button>
              <button class="btn btn-success" (click)="Exportar(ListaPlacarGeral[i], 'PlacarGeral')" style="margin-left: 1em;">Exportar</button>
            </div>
            <div style="text-align: right">
              <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
              <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt3.filterGlobal($event.target.value, 'contains')">
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr [pSelectableRow]="rowData" [ngStyle]="rowData.confirmado && {'background' : 'lightgreen'}">
              <td *ngFor="let col of columns">
                <span>{{rowData[col.field]}}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div *ngIf="ModoOrganizador">
          <hr style="width: 50%;">

          <label>Escolas</label> <br>
          <p-table #dt4 [columns]="ColunasEscolas" [value]="ConsultoresSelecionados[i]" [paginator]="true" [rows]="10" [responsive]="true">
            <ng-template pTemplate="caption" class="col-sm-4">
              <div style="float: left;" *ngIf="ModoOrganizador">
                <button class="btn btn-primary" (click)="Imprimir(ListaEscolas)">Imprimir</button>
                <button class="btn btn-success" (click)="Exportar(ListaEscolas, 'Escolas')" style="margin-left: 1em;">Exportar</button>
              </div>
              <div style="text-align: right">
                <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
                <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt4.filterGlobal($event.target.value, 'contains')">
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th style="width: 7em;">
                  Ação
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
              <tr [pSelectableRow]="rowData" [ngStyle]="rowData.confirmado && {'background' : 'lightgreen'}">
                <td *ngFor="let col of columns">
                  <span>{{rowData[col.field]}}</span>
                </td>
                <td>
                  <button class="btn btn-warning" (click)="EditarConsultor(rowData)">Editar</button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>



        <hr>
      </div>
    </div>

    <!--CAMPANHAS-->
    <div *ngIf="AbaAtual == 2">
      <app-campanhas></app-campanhas>
    </div>

    <!--PROVAS-->
    <div *ngIf="AbaAtual == 3">
      <app-provas></app-provas>
    </div>

    <!--PROVA ATUAL-->
    <div *ngIf="AbaAtual == 4">
      <div *ngIf="CarregadoPA" class="container-fluid">
        <div class="box-pa row" *ngFor="let PA of ProvaAtual; index as i">
          <div class="row col-12" style="margin-bottom: 10px;">
            <div class="col-md-2">
              <label>ID</label>
              <input type="text" class="form-control" [(ngModel)]="PA.idcampanhas" readonly>
            </div>
            <div class="col-md-10">
              <label>Campanha</label>
              <input type="text" class="form-control" [(ngModel)]="PA.campanha" readonly>
            </div>
          </div>
          <hr style="height: 4px;width: 100%;">
          <div class="col-md-2">
            <label>ID Prova</label>
            <input [readonly]="!Editando[i].editar" type="text" class="form-control" [(ngModel)]="PA.idprovas">
          </div>
          <div class="col-md-5">
            <label>Prova</label>
            <select [disabled]="!Editando[i].editar" class="form-control" [(ngModel)]="PA.idprovas" (change)="AlterarProva($event.target.value, i)">
              <option *ngFor="let Prova of ListaTodasProvas[i]" [value]="Prova.idprovas">{{Prova.descricao}}</option>
            </select>
          </div>
          <div class="row col-md-12" style="margin-top: 10px;">
            <div class="col-md-3">
              <label>Data Inicial</label>
              <input [readonly]="!Editando[i].editar" type="date" class="form-control" [(ngModel)]="PA.datainicial">
            </div>
            <div class="col-md-3">
              <label>Hora Inicial</label>
              <input [readonly]="!Editando[i].editar" type="time" class="form-control" [(ngModel)]="PA.horainicial">
            </div>
            <div class="col-md-3">
              <label>Data Final</label>
              <input [readonly]="!Editando[i].editar" type="date" class="form-control" [(ngModel)]="PA.datafinal">
            </div>
            <div class="col-md-3">
              <label>Hora Final</label>
              <input [readonly]="!Editando[i].editar" type="time" class="form-control" [(ngModel)]="PA.horafinal">
            </div>
          </div>
  
          <div class="col-md-12" style="margin-top: 2em;">
            <button class="btn" [ngClass]="{'btn-warning' : !Editando, 'btn-success': Editando}" (click)="EditarProvaAtual(PA.idcampanhas)">{{Editando[i].btnText}}</button>
          </div>
        </div>
      </div>
    </div>

    <!--DOWNLOADS-->
    <div *ngIf="AbaAtual == 5">
      <app-downloads></app-downloads>
    </div>

  </div>

</div>

<p-dialog header="Detalhes sobre o Lead" *ngIf="MostraObs" [visible]="MostraObs" (visibleChange)="MostraObs = $event" [responsive]="true" showEffect="fade" [modal]="true">

  <div class="row col-md-12">
    <div class="col-md-6">
      <label>Observações</label>
      <textarea [(ngModel)]="LeadSelecionado.observacao" class="form-control"></textarea>
    </div>
    <div class="col-md-2">
      <label>Confirmado</label>
      <input type="checkbox" [(ngModel)]="LeadSelecionado.confirmado" class="form-control">
    </div>
    <div class="col-md-2">
      <label>Acesso</label>
      <input type="text" [(ngModel)]="LeadSelecionado.senha" class="form-control" readonly>
    </div>
    <div class="col-md-2" *ngIf="ModoOrganizador">
      <label>Consultor</label>
      <select class="form-control" [(ngModel)]="LeadSelecionado.idconsultores">
        <option *ngFor="let Consultor of ListaEscolas" [value]="Consultor.idconsultores">{{Consultor.unidade}} ({{Consultor.estado}})</option>
      </select>
    </div>
    <div class="col-md-12">
      <hr>
    </div>
    <div class="col-md-12">
      <button class="btn btn-success" (click)="SalvarObservacao()">Salvar</button>

      <button class="btn-success" (click)="Contatar(1)" style="float: right;">Contatar Whatsapp</button>
      <button class="btn-primary" (click)="Contatar(2)" style="float: right; margin-left: 1em;">Contatar E-mail</button>
    </div>
  </div>
  <div class="col-md-12" style="margin-top: 1em;">
    <button class="btn btn-danger" (click)="MostraObs = false">Fechar</button>
  </div>

</p-dialog>

<p-dialog header="Dados do Consultor" *ngIf="MostraConsultor" [visible]="MostraConsultor" (visibleChange)="MostraConsultor = $event" [responsive]="true" showEffect="fade" [modal]="true">

  <div class="row col-md-12">
    <div class="col-md-3">
      <label>Nome</label>
      <input type="text" class="form-control" [(ngModel)]="FormConsultor.nome">
    </div>
    <div class="col-md-3">
      <label>Estado</label>
      <input type="text" class="form-control" [(ngModel)]="FormConsultor.estado">
    </div>
    <div class="col-md-3">
      <label>Cidade</label>
      <input type="text" class="form-control" [(ngModel)]="FormConsultor.cidade">
    </div>
    <div class="col-md-3">
      <label>Unidade</label>
      <input type="text" class="form-control" [(ngModel)]="FormConsultor.unidade">
    </div>
    <div class="col-md-3">
      <label>E-mail</label>
      <input type="text" class="form-control" [(ngModel)]="FormConsultor.email">
    </div>
    <div class="col-md-3">
      <label>Senha</label>
      <input type="text" class="form-control" [(ngModel)]="FormConsultor.senha">
    </div>
    <div class="col-md-3">
      <label>Telefone</label>
      <input type="text" class="form-control" [(ngModel)]="FormConsultor.telefone">
    </div>
    <div class="col-md-3">
      <label>Habilitado</label>
      <input type="checkbox" class="form-control" [(ngModel)]="FormConsultor.habilitado">
    </div>
  </div>

  <div class="col-md-12">
    <hr>
  </div>

  <div class="col-md-12" style="margin-top: 1em;">
    <button class="btn btn-danger" (click)="MostraConsultor = false">Caneclar</button>
    <button class="btn btn-success" (click)="SalvarConsultor()" style="float: right;">Salvar</button>
  </div>

</p-dialog>
</div>