import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GlobalFunctions } from 'src/environments/environment';
import { LeadService } from '../captacao/leadService';
import { ProvaService } from '../provas/provaService';

@Component({
  selector: 'app-participacaoprova',
  templateUrl: './participacaoprova.component.html',
  styleUrls: ['./participacaoprova.component.scss']
})
export class ParticipacaoprovaComponent implements OnInit {

  LinkProva: SafeResourceUrl;
  idleads: number;
  idcampanhas: number;
  idprovas: number;

  constructor(
    private sanitizer: DomSanitizer,
    private globalFunc: GlobalFunctions,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private provaService: ProvaService
  ) { }

  ngOnInit(): void {
    this.idleads = this.route.snapshot.params['idleads'];
    this.idcampanhas = this.route.snapshot.params['idcampanhas'];
    this.provaService.getProvaAtual().then(resProvaAtual => {
      let DataAtual: Date = new Date();
      this.idprovas = resProvaAtual.idprovas;
      if (new Date(resProvaAtual.datainicial) <= DataAtual && new Date(resProvaAtual.datafinal) >= DataAtual) { // ESTA NO INTERVALO VALIDO
        if (!resProvaAtual.habilitado) {
          alert("Essa prova está temporariamente desativada");
          return;
        }
        localStorage.setItem("idprovas", this.idprovas.toString());
        localStorage.setItem("idleads", this.idleads.toString());
        localStorage.setItem("idcampanhas", this.idcampanhas.toString());
        // VERIFICA SE O LEAD JA PARTICIPOU DA PROVA
        this.leadService.getParticipacaoNaProva(this.idleads, this.idprovas, this.idcampanhas).then(resParticipacao => {
          if (resParticipacao.length == 0) { // LEAD NAO ENCONTRADO
            alert("Endereço inválido!");
          }
          else if (resParticipacao.length == 1 && resParticipacao[0].idprovas != this.idprovas) { // AINDA NAO PARTICIPOU DESSA PROVA
            this.LinkProva = this.sanitizer.bypassSecurityTrustResourceUrl("http://18.228.91.135/games/89/index.html");
            //this.LinkProva = this.sanitizer.bypassSecurityTrustResourceUrl(this.globalFunc.APIUrl() + '/provas/' + this.idprovas);
          }
          else { // JA PARTICIPOU DA PROVA
            alert("Você já participou dessa etapa!");
          }
        })
      }
      else {
        alert("O prazo para realizar essa prova excedeu!");
      }
    })

  }

}
