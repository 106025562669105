import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { CampanhasLeadsResults, CampanhasModel, EtapaModel } from './campanhasModel';

@Injectable()
export class CampanhaService {

    Rota: String = this.globalFunc.APIUrl() + "/apicap";
    idconsultores: number = parseInt(localStorage.getItem("idconsultores"));

    constructor(
      private http: HttpClient,
      private globalFunc: GlobalFunctions
    ) { }

    cadastrarCampanha(formCampanha: CampanhasModel) {
      return this.http.post<ResponseModel>(`${this.Rota}/cadastrarCampanha`, formCampanha)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    criarEtapaAtual(formEtapa: EtapaModel) {
      return this.http.post<ResponseModel>(`${this.Rota}/criarEtapaAtual`, formEtapa)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    atualizarCampanha(formConsultor: CampanhasModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/atualizarCampanha`, formConsultor)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    removerCampanha(idcampanhas: number) {
      const response = this.http
        .delete(`${this.Rota}/removerCampanha/${idcampanhas}`)
        .toPromise();
      return response;
    }

    deletarProva(idcampanhas: number) {
      const response = this.http
        .delete(`${this.Rota}/deletarprova/${idcampanhas}`)
        .toPromise();
      return response;
    }

    deletarProvaAtual(idcampanhas: number) {
      const response = this.http
        .delete(`${this.Rota}/deletarprovaatual/${idcampanhas}`)
        .toPromise();
      return response;
    }

    getAllCampanhas() {
      return this.http.get<CampanhasModel[]>(`${this.Rota}/getAllCampanhas`)
        .toPromise()
        .then(response => <CampanhasModel[]>response)
        .then(data => {
          return <CampanhasModel[]>data['response'];
        })
    }

    getAllCampanhasEnabled() {
        return this.http.get<CampanhasModel[]>(`${this.Rota}/getAllCampanhasEnabled`)
          .toPromise()
          .then(response => <CampanhasModel[]>response)
          .then(data => {
            return <CampanhasModel[]>data['response'];
          })
    }

    getCampanha(idcampanhas: number) {
      return this.http.get<CampanhasModel>(`${this.Rota}/getCampanha/${idcampanhas}`)
        .toPromise()
        .then(response => <CampanhasModel>response)
        .then(data => {
          return <CampanhasModel>data['response'][0];
        })
    }

    getAllCampanhasLeads() {
      return this.http.get<CampanhasLeadsResults[]>(`${this.Rota}/getAllCampanhasLeads`)
      .toPromise()
      .then(response => <CampanhasLeadsResults[]>response)
      .then(data => {
        return <CampanhasLeadsResults[]>data['response'];
      })
    }

    getCampanhasLeadsConsultor(Adm: boolean) {
      return this.http.get<CampanhasLeadsResults[]>(`${this.Rota}/getCampanhasLeadsConsultor/${this.idconsultores}/${(Adm) ? 1 : 0}`)
      .toPromise()
      .then(response => <CampanhasLeadsResults[]>response)
      .then(data => {
        return <CampanhasLeadsResults[]>data['response'];
      })
    }

}
