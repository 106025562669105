import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';

@Injectable()
export class CompartilhadoService {

  Rota: String = this.globalFunc.APIUrl() + "/apicap";
  idconsultores: number = parseInt(localStorage.getItem("idconsultores"));

  constructor(
    private http: HttpClient,
    private globalFunc: GlobalFunctions
  ) { }

  LerJson(Caminho: string) {
    return this.http.get(Caminho, { responseType: 'text' })
    .toPromise()
    .then(response => <any>response)
    .then(data => { return <any>JSON.parse(data); });
  }

  ExportarCSV(Lista: any) {
    return this.http.post<boolean>(`${this.Rota}/exportarCSV`, Lista)
      .toPromise()
      .then(response => <boolean>response)
      .then(data => {
        return <boolean>data;
      });
  }

}
