import { Time } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ConsultorModel } from '../cad-usuario/consultorModel';
import { ConsultorService } from '../cad-usuario/consultorService';
import { CampanhaService } from '../campanhas/campanhaService';
import { CampanhasLeads, CampanhasModel } from '../campanhas/campanhasModel';
import { LeadService } from '../captacao/leadService';
import { LeadsModel, ParticipacoesModel } from '../captacao/leadsModel';
import { CompartilhadoService } from '../compartilhado/compartilhadoService';
import { ProvaService } from '../provas/provaService';
import { PlacarEscolasModel, PlacarModel, ProvaAtualDadosModel, ProvaAtualModel, ProvasModel } from '../provas/provasModel';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  ListaCampanhas: CampanhasLeads[];
  ColunasLeads: any[];
  ColunasParticipacoes: any[];
  ColunasPlacar: any[];
  ListaEscolas: PlacarEscolasModel[] = new Array<PlacarEscolasModel>();
  SelectEscolas: any[] = [];
  ConsultoresSelecionados: any[] = [];
  ColunasEscolas: any[];
  MostraObs: boolean = false;
  LeadSelecionado: LeadsModel;
  ModoAdministrador: boolean = false;
  ModoOrganizador: boolean = false;
  ListaPlacar: PlacarModel[] = new Array<PlacarModel>();
  ListaPlacarGeral: any[] = [];
  AbaAtual: number = 1;
  txtHeader: string = "Visão Geral";
  PA: ProvaAtualDadosModel;
  ProvaAtual: ProvaAtualDadosModel[] = new Array<ProvaAtualDadosModel>();
  Editando: any[] = [];
  btnEditarText: string = "Editar";
  ListaTodasCampanhas: CampanhasModel[] = new Array<CampanhasModel>();
  ListaTodasProvas: ProvasModel[] = new Array<ProvasModel>();
  LTP: any[] = [];
  idprovaatualA: number = 0;
  MostraConsultor: boolean = false;
  FormConsultor: ConsultorModel = new ConsultorModel();
  ListaCampanhasConsultor: LeadsModel[];
  IdCampanhas: number;
  Carregado: boolean = false;
  CarregadoPA: boolean = false;

  constructor(
    private globalFunc: GlobalFunctions,
    private campanhaService: CampanhaService,
    private leadService: LeadService,
    private provaService: ProvaService,
    private consultorService: ConsultorService
  ) { }

  ngOnInit(): void {
    this.MostraConsultor = false;
    this.MostraObs = false;
    this.AjustarTamanho();

    if (localStorage.getItem("tipo_user") == "1"){
      this.ModoAdministrador = true;
      this.ModoOrganizador = true;
    } else if (localStorage.getItem("tipo_user") == "2"){
      this.ModoOrganizador = true;
      this.IdCampanhas = parseInt(localStorage.getItem("idcampanhas"));
    } else {
      this.IdCampanhas = parseInt(localStorage.getItem("idcampanhas"));
    }

    this.ColunasLeads = [
      { field: 'unidade', header: 'Escola' },
      { field: 'nome', header: 'Nome' },
      { field: 'estado', header: 'Estado' },
      { field: 'cidade', header: 'Cidade' },
      { field: 'telefone', header: 'Telefone' },
      { field: 'email', header: 'E-mail' },
      { field: 'datanascimento', header: 'Data de Nascimento' },
      { field: 'insert_date', header: 'Data captação' },
    ];
    this.ColunasParticipacoes = [
      { field: 'prova', header: 'Prova' },
      { field: 'pontuacao', header: 'Pontuação' },
      { field: 'data_participacao', header: 'Data' },
    ];
    this.ColunasPlacar = [
      { field: 'posicao', header: 'Classificação' },
      { field: 'participante', header: 'Participante' },
      { field: 'Cidade', header: 'Cidade' },
      { field: 'prova', header: 'Prova' },
      { field: 'pontuacao', header: 'Pontuação' },
      { field: 'data_participacao', header: 'Data' },
    ];
    this.ColunasEscolas = [
      { field: 'estado', header: 'Estado' },
      { field: 'cidade', header: 'Cidade' },
      { field: 'unidade', header: 'Escola' },
      { field: 'data_cadastro', header: 'Data Cadastro' },
      { field: 'participantes', header: 'Inscrições' },
    ];
    this.campanhaService.getCampanhasLeadsConsultor(this.ModoOrganizador).then(resCampanhas => {
      this.ListaCampanhas = new Array<CampanhasLeads>();
      for (let i = 0; i < resCampanhas.length; i++) {
        let CampanhaAdicionada: boolean = false;
        let LeadAdicionado: boolean = false;
        for (let o = 0; o < this.ListaCampanhas.length; o++) {
          if (resCampanhas[i].idcampanhas == this.ListaCampanhas[o].Campanha.idcampanhas) {
            CampanhaAdicionada = true;
          }
          for (let u = 0; u < this.ListaCampanhas[o].Leads.length; u++) {
            if (resCampanhas[i].idleads == this.ListaCampanhas[o].Leads[u].idleads) {
              LeadAdicionado = true;
              break;
            }
          }
        }
        if (!CampanhaAdicionada) {
          let CL: CampanhasLeads = new CampanhasLeads();
          CL.Provas = new Array<ProvasModel>();
          CL.Campanha = new CampanhasModel();
          CL.Campanha.idcampanhas = resCampanhas[i].idcamp;
          CL.Campanha.nome = resCampanhas[i].nomec;
          CL.Campanha.descricao = resCampanhas[i].descricao;
          CL.Campanha.datainicial = resCampanhas[i].datainicial;
          CL.Campanha.datafinal = resCampanhas[i].datafinal;
          CL.LinkCaptacao = this.globalFunc.APIUrl() + "/captacao/" + localStorage.getItem("unidade") + "-" + localStorage.getItem("idconsultores") + "-" + CL.Campanha.idcampanhas;
          CL.LinkCadastroConsultores = this.globalFunc.APIUrl() + "/cadastramento/" + resCampanhas[i].idcamp;
          //console.log(CL.LinkCadastroConsultores)
          CL.Leads = new Array<LeadsModel>();
          this.ListaCampanhas.push(CL);
        }
        if (!LeadAdicionado) {
          let Lead: LeadsModel = new LeadsModel();
          Lead.idleads = resCampanhas[i].idleads;
          Lead.nome = resCampanhas[i].nome;
          Lead.senha = resCampanhas[i].senha;
          Lead.estado = resCampanhas[i].estado;
          Lead.cidade = resCampanhas[i].cidade;
          Lead.email = resCampanhas[i].email;
          Lead.telefone = resCampanhas[i].telefone;
          Lead.datanascimento = resCampanhas[i].datanascimento;
          Lead.idcampanhas = resCampanhas[i].idcampanhas;
          Lead.observacao = resCampanhas[i].observacao;
          Lead.confirmado = resCampanhas[i].confirmado;
          Lead.idconsultores = resCampanhas[i].idconsultores;
          Lead['insert_date'] = resCampanhas[i].insert_date;
          Lead['unidade'] = resCampanhas[i].unidade;
          Lead.Participacoes = new Array<ParticipacoesModel>();
          if (Lead.idleads > 0)
            this.ListaCampanhas[this.ListaCampanhas.length - 1].Leads.push(Lead);
        }
        let Participacao: ParticipacoesModel = new ParticipacoesModel();
        Participacao.idpontuacao = resCampanhas[i].idpontuacao;
        Participacao.idprovas = resCampanhas[i].idprovas;
        Participacao.prova = resCampanhas[i].prova;
        Participacao.pontuacao = resCampanhas[i].pontuacao;
        Participacao.data_participacao = resCampanhas[i].data_participacao;
        if (Participacao.idpontuacao > 0) {
          this.ListaCampanhas[this.ListaCampanhas.length - 1].Leads[this.ListaCampanhas[this.ListaCampanhas.length - 1].Leads.length - 1].Participacoes.push(Participacao);
        }
      }
      for (let i = 0; i < this.ListaCampanhas.length; i++) {
        this.ListaCampanhas[i].LeadsConfirmados = 0;
        for (let o = 0; o < this.ListaCampanhas[i].Leads.length; o++) {
          if (this.ListaCampanhas[i].Leads[o].confirmado)
            this.ListaCampanhas[i].LeadsConfirmados++;
        }
      }

      if(!this.ModoAdministrador) {
        //console.log(this.ListaCampanhas);
        let LC: CampanhasLeads = new CampanhasLeads();
        let campanhaExiste = false;
        for(let i = 0; i < this.ListaCampanhas.length; i++){
          let CampanhaID = this.ListaCampanhas[i].Campanha.idcampanhas;
          //console.log(CampanhaID)
          if(CampanhaID == this.IdCampanhas) {
            LC = this.ListaCampanhas[i];
            //console.log(LC)
            this.ListaCampanhas = [LC];
            campanhaExiste = true;
          }
        }
        if(campanhaExiste == false){
          this.ListaCampanhas = [];
        }
      }

      //console.log(this.ListaCampanhas);

      this.LoopClassificados(0);

      this.AtualizarProvaAtual();
      this.AtualizarProvasCampanha();



      this.provaService.getPlacarEscolas().then(resConsultores => {
        this.ListaEscolas = resConsultores;
        //console.log(resConsultores)
        //console.log(this.ListaEscolas.length)
        //console.log(this.SelectEscolas)
        for(let i = 0; i < this.ListaEscolas.length; i++){
          if(this.ListaEscolas[i].tipo_user !== 0) {
          }
          else if(this.SelectEscolas.length == 0){
            this.SelectEscolas.push([resConsultores[i]])
          } else {
            let exist = false;
            for(let j = 0; j < this.SelectEscolas.length; j++){
              if(this.SelectEscolas[j][0].idcampanhas == resConsultores[i].idcampanhas){
                this.SelectEscolas[j].push(resConsultores[i]);
                exist = true;
              }
            }
            if(exist == false){
              this.SelectEscolas.push([resConsultores[i]]);
            }
          }
        }
        
        if(this.ModoOrganizador && this.ModoAdministrador){
          if(this.SelectEscolas.length !== this.ListaCampanhas.length){
            this.ConsultoresSelecionados = this.SelectEscolas;
            for(let i = 0; i < this.ListaCampanhas.length - 1; i++){
              this.ConsultoresSelecionados.push([[]]);
            }
          } else {
            this.ConsultoresSelecionados = this.SelectEscolas;
          }
        } else {
          let isNull = false;
          for(let i = 0; i < this.SelectEscolas.length; i++){
            if(this.SelectEscolas[i][0].idcampanhas == this.IdCampanhas){
              this.ConsultoresSelecionados.push(this.SelectEscolas[i]);
              isNull = true;
            }
          }

          if(!isNull){
            this.ConsultoresSelecionados = [[]];
          }
        }
        this.Carregado = true;
      });
    });
  }

  AtualizarProvaAtual() {
    this.provaService.getProvaAtualDados().then(resProvaAtual => {
      //console.log(resProvaAtual)
      this.ProvaAtual = [];
      this.PA = new ProvaAtualDadosModel;
      if(this.ModoAdministrador){
        for(let i = 0; i < resProvaAtual.length; i++){
          this.PA = resProvaAtual[i];
          let HoraInicial: string = resProvaAtual[i].datainicial.toString().split("T")[1].substring(0, 5);
          let HoraFinal: string = resProvaAtual[i].datafinal.toString().split("T")[1].substring(0, 5);
          this.PA.datainicial = <any>(resProvaAtual[i].datainicial.toString().split("T")[0]);
          this.PA.datafinal = <any>(resProvaAtual[i].datafinal.toString().split("T")[0]);
          this.PA.horainicial = <any>(HoraInicial);
          this.PA.horafinal = <any>(HoraFinal);
          this.ProvaAtual.push(this.PA)
        }
      } else {
        for(let i = 0; i < resProvaAtual.length; i++){
          if(resProvaAtual[i].idcampanhas == this.IdCampanhas){
            this.PA = resProvaAtual[i];
            let HoraInicial: string = resProvaAtual[i].datainicial.toString().split("T")[1].substring(0, 5);
            let HoraFinal: string = resProvaAtual[i].datafinal.toString().split("T")[1].substring(0, 5);
            this.PA.datainicial = <any>(resProvaAtual[i].datainicial.toString().split("T")[0]);
            this.PA.datafinal = <any>(resProvaAtual[i].datafinal.toString().split("T")[0]);
            this.PA.horainicial = <any>(HoraInicial);
            this.PA.horafinal = <any>(HoraFinal);
            this.ProvaAtual.push(this.PA)
          }
        }
      }
      // console.log(this.ProvaAtual);
      this.campanhaService.getAllCampanhas().then(resCampanhas => {
        this.ListaTodasCampanhas = resCampanhas;
        this.Editando = [];
        for(let i = 0; i < this.ProvaAtual.length; i++) {
          let Edit = {
            idcampanhas: this.ProvaAtual[i].idcampanhas,
            editar: false,
            btnText: 'Editar'
          };
          this.Editando.push(Edit);
        }
        // console.log(this.ListaTodasCampanhas)
        //console.log(this.Editando)
        this.provaService.getAllProvasEnabled().then(resProvas => {
          //console.log(resProvas)
          this.ListaTodasProvas = [];
          this.LTP = [];
          for(let i = 0; i < resProvas.length; i++) {
            if(this.LTP.length == 0) {
              this.LTP.push([resProvas[i]])
            } else {
              let exist = false;
              for(let j = 0; j < this.LTP.length; j++){
                if(this.LTP[j][0].idcampanhas == resProvas[i].idcampanhas){
                  this.LTP[j].push(resProvas[i]);
                  // console.log('i',resProvas[i])
                  exist = true;
                }
              }
              if(exist == false){

                this.LTP.push([resProvas[i]]);
                

              }
            }
          }
          this.ListaTodasProvas = this.LTP;
          this.CarregadoPA = true;
          //console.log(this.ListaTodasProvas)
        });
      });
    });
  }

  AtualizarProvasCampanha() {
    let idconsultores = parseInt(localStorage.getItem("idconsultores"));
    let idcampanhas = parseInt(localStorage.getItem("idcampanhas"));
    if (this.ModoAdministrador){
      idconsultores = 0;
    } else if (this.ModoOrganizador){
      idconsultores = 0;
    }
    this.provaService.getPlacar(idconsultores, idcampanhas).then(resPlacar => {
      this.ListaPlacar = resPlacar;
      for (let i = 0; i < this.ListaPlacar.length; i++) {
        this.ListaPlacar[i].posicao = i + 1;
      }
    });
    this.provaService.getPlacar(0, idcampanhas).then(resPlacar => {
      let LPG = resPlacar;
      if(this.ModoAdministrador) {
        for(let i = 0; i < LPG.length; i++) {
          if(this.ListaPlacarGeral.length == 0) {
            this.ListaPlacarGeral.push([LPG[i]]);
          } else {
            let exists = false;
            for(let j = 0; j < this.ListaPlacarGeral.length; j++){
              if(this.ListaPlacarGeral[j][0].idcampanhas == LPG[i].idcampanhas){
                this.ListaPlacarGeral[j].push(LPG[i]);
                exists = true;
              }
            }
  
            if(!exists){
              this.ListaPlacarGeral.push([LPG[i]]);
            }
          }
        }
        
        for(let i = 0; i < this.ListaPlacarGeral.length; i++){
          for (let j = 0; j < this.ListaPlacarGeral[i].length; j++) {
            this.ListaPlacarGeral[i][j].posicao = j + 1;
          }
        }
      } else {
        this.ListaPlacarGeral.push([]);
        for(let i = 0; i < LPG.length; i++){
          if(this.IdCampanhas == LPG[i].idcampanhas){
            this.ListaPlacarGeral[0].push(LPG[i]);
          }
        }
        //console.log(this.ListaPlacarGeral)
        for (let j = 0; j < this.ListaPlacarGeral[0].length; j++) {
          this.ListaPlacarGeral[0][j].posicao = j + 1;
          //console.log(this.ListaPlacarGeral[j].posicao)
        }
      }

      //console.log(this.ListaPlacarGeral)
    })
  }

  CopiarLink(inputElement){
    inputElement.select();
    document.execCommand('copy');
  }

  VerObservacoes(Lead: LeadsModel) {
    //console.log(Lead);
    this.LeadSelecionado = Lead;
    this.MostraObs = true;
  }

  SalvarObservacao() {
    this.LeadSelecionado.datanascimento = new Date(this.LeadSelecionado.datanascimento, );
    this.leadService.salvarObsLead(this.LeadSelecionado).then(resAtualizacao => {
      if (resAtualizacao.status  == 200) {
        //console.log("Observação salva com sucesso!");
        this.ngOnInit();
      }
      else {
        //console.log("Erro ao salvr observação!");
      }
    })
  }

  Sair() {
    localStorage.clear();
    window.location.href = this.globalFunc.APIUrl() + "/login";
  }

  MudarAba(Aba: number) {
    this.AbaAtual = Aba;
    if (Aba == 1) {
      this.txtHeader = "Visão Geral";
    }
    else if (Aba == 2) {
      this.txtHeader = "Campanhas";
    }
    else if (Aba == 3) {
      this.txtHeader = "Provas";
    }
    else if (Aba == 4) {
      this.txtHeader = "Prova Atual";
      this.AtualizarProvaAtual();
    }
    else if (Aba == 5) {
      this.txtHeader = "Downloads";
    }
  }

  EditarProvaAtual(idcampanhas) {
    //console.log(idcampanhas)
    let index;
    // console.log(this.Editando);
    for(let i = 0; i < this.Editando.length; i++){
      if(this.Editando[i].idcampanhas == idcampanhas){
        this.Editando[i].editar = !this.Editando[i].editar;
        this.Editando[i].btnText = (this.Editando[i].editar) ? "Salvar" : "Editar";
        index = i;
      }
    }
    if (!this.Editando[index].editar) {

      let ProvaAtual: ProvaAtualModel = new ProvaAtualModel();
      ProvaAtual.idprovaatual = this.ProvaAtual[index].idprovaatual;
      ProvaAtual.idcampanhas = this.ProvaAtual[index].idcampanhas;
      ProvaAtual.idprovas = this.ProvaAtual[index].idprovas;
      ProvaAtual.habilitado = this.ProvaAtual[index].habilitado;
      ProvaAtual.datainicial = <any>(this.ProvaAtual[index].datainicial.toString().split("T")[0] + " " + this.ProvaAtual[index].horainicial);
      ProvaAtual.datafinal = <any>(this.ProvaAtual[index].datafinal.toString().split("T")[0] + " " + this.ProvaAtual[index].horafinal);

      if (ProvaAtual.idprovas != this.idprovaatualA && confirm("Você está mudando a prova atual, deseja enviar o e-mail de notificação para os participantes?")) {
        ProvaAtual.Notificacao = true;
      }
      else {
        ProvaAtual.Notificacao = false;
      }

      this.provaService.atualizarProvaAtual(ProvaAtual).then(resAtualizar => {
        this.CarregadoPA = false;
        this.AtualizarProvaAtual();
      });
    }
    else {
      this.idprovaatualA = this.ProvaAtual[index].idprovas;
    }
  }

  AlterarProva(Prova: string, index: number) {
    this.ProvaAtual[index].prova = Prova;
  }

  LoopClassificados(index: number) {
    if(index < this.ListaCampanhas.length) {
      this.leadService.getClassificados(this.ListaCampanhas[index].Campanha.idcampanhas, parseInt(localStorage.getItem("idconsultores"))).then(resClass => {
        this.ListaCampanhas[index].LeadsParticipou = resClass;
        //console.log(this.ListaCampanhas[index].LeadsParticipou.length);
        if(this.ListaCampanhas[index].LeadsParticipou.length > 0) {
          let porcentagem = this.ListaCampanhas[index].LeadsParticipou[0]['classificados'];
          let setPorcentagem = porcentagem / 100 * this.ListaCampanhas[index].LeadsParticipou.length;
          this.ListaCampanhas[index].LeadsParticipou.splice(setPorcentagem);
          this.ListaCampanhasConsultor = new Array<LeadsModel>();
  
          for(let i = 0; i < this.ListaCampanhas[index].LeadsParticipou.length; i++) {
            if(this.ListaCampanhas[index].LeadsParticipou[i].idconsultores == parseInt(localStorage.getItem("idconsultores"))) {
              this.ListaCampanhasConsultor.push(this.ListaCampanhas[index].LeadsParticipou[i])
            }
          }
  
          this.ListaCampanhas[index].LeadsParticipou = this.ListaCampanhasConsultor
        } else {
          
        }

        index++
        this.LoopClassificados(index)
      });
    } else {
    }
  }

  AjustarTamanho() {
    let base = document.querySelector(".pickMe");
    let obj = base.classList;

    if(obj[0] === "BaseSite"){
      return;
    } else if(obj[1] === "BaseSite"){
      return;
    } else {
      base.classList.add("BaseSite");
    }

  }

  Imprimir(Lista: any[]) {
    this.globalFunc.ImprimirTabela(Lista);
  }

  Exportar(List: any[], Title: string) {
    Title = localStorage.getItem("idconsultores") + "_" + Title;
    this.globalFunc.uploadFiles([{header: "Titulo", data: Title}, {header: "Lista", data: JSON.stringify(List)}], "POST", "/apicap/exportarCSV", "any");
    setTimeout(() => {
      window.open(Title + '.csv', "_blank");
    }, 1000);
  }

  EditarConsultor(Escola: PlacarEscolasModel) {
    this.consultorService.getConsultor(Escola.idconsultores).then(resConsultor => {
      this.FormConsultor = resConsultor;
      this.MostraConsultor = true;
    });
  }

  SalvarConsultor() {
    this.consultorService.atualizarConsultor(this.FormConsultor).then(resUpdate => {
      if (resUpdate.status == 200) {
        alert("Dados atualizados com sucesso!");
        this.ngOnInit();
      }
      else {
        alert("Erro ao atualizar dados!");
        //console.log(resUpdate.error);
      }
    });
  }

  Contatar(Tipo: number) {
    if (Tipo == 1) {
      window.open('https://wa.me/55' + this.LeadSelecionado.telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""), '_blank');
    }
    else if (Tipo == 2) {
      window.open('mailto:' + this.LeadSelecionado.email, '_blank');
    }
  }

}
