import { Component, OnInit } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { DownloadsModel } from './downloadsModel';
import { DownloadsService } from './downloadsService';
import { PastasModel } from './PastasModel';
import { PastasService } from './pastasService';
import { saveAs } from 'file-saver';
import { DashboardComponent } from './../dashboard/dashboard.component';
import { CampanhaService } from '../campanhas/campanhaService';
import { CampanhasModel } from '../campanhas/campanhasModel';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

  ModoAdministrador: boolean = false;

  FormDownload: DownloadsModel = new DownloadsModel();
  ListaDownloads: DownloadsModel[] = new Array<DownloadsModel>();
  AcaoDownload: string = "Cadastrar";
  ColunasDownloads: any[];

  MostraPasta: boolean = false;
  FormPasta: PastasModel = new PastasModel();
  ListaPastas: PastasModel[] = new Array<PastasModel>();
  AcaoPasta: string = "Cadastrar";
  ColunasPastas: any[];

  DownloadState: number = 1;
  PastaSelected: number;
  ActualDownloads: any[];

  IdCampanhas: number;
  dwReady: boolean = false;
  ListaCampanhas: CampanhasModel[];
  CampanhaEscolhida: number;

  constructor(
    private downloadsService: DownloadsService,
    private pastasService: PastasService,
    private globalFunc: GlobalFunctions,
    private dashboard: DashboardComponent,
    private campanhaService: CampanhaService,
  ) { }

  ngOnInit(): void {

    this.ModoAdministrador = this.dashboard.ModoAdministrador;
    console.log(this.dashboard.IdCampanhas)

    this.ColunasDownloads = [
      { field: 'descricao', header: 'Descrição' },
      { field: 'extensao', header: 'Extensão' },
      { field: 'pasta', header: 'Pasta' },
    ];

    this.ColunasPastas = [
      { field: 'descricao', header: 'Descrição' },
    ];

    if(!this.ModoAdministrador) {
      this.AtualizarDados();
    } else {
      this.campanhaService.getAllCampanhas().then(resCampanhas => {
        this.ListaCampanhas = resCampanhas;
      })
    }
  }

  AtualizarDados() {
    this.ListaPastas = [];
    this.ListaDownloads = [];
    this.ActualDownloads = [];
    this.DownloadState = 1;

    if(this.ModoAdministrador){
      this.pastasService.getAllPastasById(this.IdCampanhas).then(resPastas => {
        this.ListaPastas = resPastas;
        console.log(this.ListaPastas)
        this.downloadsService.getAllDownloadsById(this.IdCampanhas).then(resDownloads => {
          this.ListaDownloads = resDownloads;
          this.dwReady = true;
          console.log(this.ListaDownloads)
        });
      });
    } else {
      this.IdCampanhas = this.dashboard.IdCampanhas;
      this.pastasService.getAllPastasById(this.IdCampanhas).then(resPastas => {
        this.ListaPastas = resPastas;
        console.log(this.ListaPastas)
        this.downloadsService.getAllDownloadsById(this.IdCampanhas).then(resDownloads => {
          this.ListaDownloads = resDownloads;
          this.dwReady = true;
          console.log(this.ListaDownloads)
        });
      });
    }
  }

  SelectCampanha(e) {
    this.IdCampanhas = e;
    this.AtualizarDados();
  }

  //#region DOWNLOADS

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      this.FormDownload.Arquivo = event.target.files[0];
      this.FormDownload.idcampanhas = this.IdCampanhas;
      this.FormDownload.extensao = "." + this.FormDownload.Arquivo.name.split('.')[this.FormDownload.Arquivo.name.split('.').length - 1];
      if (this.FormDownload.descricao == "") {
        this.FormDownload.descricao = this.FormDownload.Arquivo.name.replace(this.FormDownload.extensao, "");
      }
    }
  }

  SalvarDownload() {
    this.FormDownload.idcampanhas = this.IdCampanhas;
    if (!this.FormDownload.Arquivo && this.FormDownload.iddownloads == 0) {
      alert("Arquivo para download não informado!");
      return;
    }
    if (this.FormDownload.descricao == "" || this.FormDownload.extensao == "" || this.FormDownload.idpastas <= 0) {
      alert("Preencha corretamente os campos para salvar!");
      return;
    }

    console.log(this.FormDownload);

    if (this.FormDownload.iddownloads > 0) {
      this.downloadsService.atualizarDownload(this.FormDownload).then(resUpdate => {
        this.ResultadoDownload(resUpdate);
      });
    }
    else {
      this.downloadsService.cadastrarDownload(this.FormDownload).then(resInsert => {
        this.ResultadoDownload(resInsert);
      });
    }
  }

  ResultadoDownload(results: ResponseModel) {
    let Novo: boolean = this.FormPasta.idpastas > 0;
    this.FormDownload.iddownloads = results.response.insertId;
    if (results.status == 200) {
      let Variavel: string = "Download_";
      this.globalFunc.uploadFiles([{header: "name", data: "downloads/" + this.FormDownload.idpastas + "/" + this.FormDownload.iddownloads + this.FormDownload.extensao }, {header: "file", data: this.FormDownload.Arquivo}], "POST", "/apicap/CopiaArquivos", Variavel);
      this.verifyEndUpload(Variavel, Novo);
    }
    else {
      alert(`Erro ao ${(!Novo) ? 'atualizar' : 'cadastrar'} Pasta!`);
      console.log(results.error);
    }
  }

  verifyEndUpload(Variavel: string, Novo: boolean) {
    setTimeout(() => {
      if (localStorage.getItem(Variavel) != "0") {
        this.FormDownload = new DownloadsModel();
        this.AcaoDownload = "Cadastrar";
        alert(`Download ${(!Novo) ? 'atualizado' : 'cadastrado'} com sucesso!`);
        this.AtualizarDados();
      }
      else {
        this.verifyEndUpload(Variavel, Novo);
      }
    }, 200);
  }

  EditarDownload(Download: DownloadsModel) {
    this.FormDownload = new DownloadsModel();
    this.FormDownload.descricao = Download.descricao;
    this.FormDownload.habilitado = Download.habilitado;
    this.FormDownload.idpastas = Download.idpastas;
    this.FormDownload.extensao = Download.extensao;
    this.FormDownload.iddownloads = Download.iddownloads;
    this.AcaoDownload = "Salvar";
  }

  ExcluirDownload(Download: DownloadsModel) {
    if (!confirm("Tem certeza que deseja excluir esse download?"))
      return;

    this.downloadsService.removerDownload(Download.iddownloads).then(resDelete => {
      this.AtualizarDados();
    });
  }

  MudaStatusDownload(Download: DownloadsModel) {
    this.downloadsService.mudaStatusDownload(Download.iddownloads).then(resUpdate => {
      this.AtualizarDados();
    });
  }

  BaixarArquivo(Download: DownloadsModel) {
    var FileSaver = require('file-saver');
    FileSaver.saveAs(`${this.globalFunc.APIUrl()}/downloads/${Download.idpastas}/${Download.iddownloads}${Download.extensao}`, `${Download.descricao}${Download.extensao}`);
    console.log(this.globalFunc.APIUrl()  + "/downloads/" + Download.idpastas + "/" + Download.iddownloads + Download.extensao)
    //window.open(this.globalFunc.APIUrl()  + "/downloads/" + Download.idpastas + "/" + Download.iddownloads + Download.extensao, "_blank");
  }

  //#endregion


  //#region PASTAS

  MudarPasta(idpastas: number) {
    this.AcaoPasta = "Cadastrar";
    if (idpastas == -1) {
      this.FormPasta = new PastasModel();
      this.MostraPasta = true;
    }
  }

  SalvarPasta() {
    if (this.FormPasta.descricao == "") {
      alert("Preencha corretamente os campos para salvar!");
      return;
    }

    this.FormPasta.idcampanhas = this.IdCampanhas;
    console.log(this.FormPasta);

    if (this.FormPasta.idpastas > 0) {
      this.pastasService.atualizarPasta(this.FormPasta).then(resUpdate => {
        this.ResultadoPasta(resUpdate);
      });
    }
    else {
      this.pastasService.cadastrarPasta(this.FormPasta).then(resInsert => {
        this.ResultadoPasta(resInsert);
      });
    }
  }

  ResultadoPasta(results: ResponseModel) {
    if (results.status == 200) {
      this.AtualizarDados();
      if (this.FormPasta.idpastas > 0) {
        this.FormPasta = new PastasModel();
        this.AcaoPasta = "Cadastrar";
      }
      else {
        setTimeout(() => {
          this.FormDownload.idpastas = results.response.insertId;
          this.MostraPasta = false;
        }, 200);
      }
    }
    else {
      alert(`Erro ao ${(this.FormPasta.idpastas > 0) ? 'atualizar' : 'cadastrar'} Pasta!`);
      console.log(results.error);
    }
  }

  EditarPasta(Pasta: PastasModel) {
    this.FormPasta = new PastasModel();
    this.FormPasta.descricao = Pasta.descricao;
    this.FormPasta.habilitado = Pasta.habilitado;
    this.FormPasta.idpastas = Pasta.idpastas;
    this.AcaoPasta = "Salvar";
  }

  ExcluirPasta(Pasta: PastasModel) {
    if (!confirm("Tem certeza que deseja excluir essa pasta?"))
      return;

    this.pastasService.removerPasta(Pasta.idpastas).then(resDelete => {
      this.AtualizarDados();
    });
  }

  MudaStatusPasta(Pasta: PastasModel) {
    this.pastasService.mudaStatusPasta(Pasta.idpastas).then(resUpdate => {
      this.AtualizarDados();
    });
  }

  //#endregion

  OpenFolder(folder) {
    this.ActualDownloads = [];

    for(let i = 0; i < this.ListaDownloads.length; i++) {
      if(this.ListaDownloads[i].idpastas == folder.idpastas && this.ListaDownloads[i].habilitado == true) {
        this.ActualDownloads.push(this.ListaDownloads[i]);
      }
    }

    this.DownloadState = 2;
  }

}
