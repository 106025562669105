import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalFunctions } from 'src/environments/environment';
import { ConsultorService } from '../cad-usuario/consultorService';
import { LoginModel } from './loginModel';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  Usuario: LoginModel = new LoginModel();
  txtInformativo: string = "";

  constructor(
    private router: Router,
    private consultorService: ConsultorService,
    private globalFunc: GlobalFunctions
  ) { }

  ngOnInit(): void {
    this.AjustarTamanho();
  }

  Login() {
    this.consultorService.Login(this.Usuario).then(resLogin => {
      if (resLogin) {
        localStorage.setItem("idconsultores", resLogin.idconsultores.toString());
        localStorage.setItem("idcampanhas", resLogin.idcampanhas.toString());
        localStorage.setItem("usuario", this.Usuario.telefone);
        localStorage.setItem("nome", resLogin.nome);
        localStorage.setItem("tipo_user", resLogin.tipo_user.toString());
        localStorage.setItem("unidade", this.globalFunc.ReplaceCaracteres(resLogin.unidade, ["-", " "], "_"));
        this.txtInformativo = "Login efetuado com sucesso!";
        setTimeout(() => {
          this.router.navigate(['dashboard']);
        }, 800);
      }
      else {
        this.txtInformativo = "Telefone ou senha incorretos!";
        setTimeout(() => {
          this.txtInformativo = "";
        }, 5000);
      }
    })
  }

  Cadastrar() {
    //this.router.navigate(['cadastramento']);
    window.location.href = window.location.href.split('/')[0] + "/cadastramento";
  }

  AjustarTamanho() {
    let base = document.querySelector(".BaseSite");
    base.classList.remove("BaseSite");
    
  }

}
