export class ConsultorModel {
    idconsultores: number = null;
    idcampanhas: number = null;
    nome: string = null;
    estado: string = "";
    cidade: string = "";
    unidade: string = null;
    tipo_user: number = null;
    email: string = null;
    senha: string = null;
    telefone: string = null;
    habilitado: boolean = true;
    aceito: boolean = null;
}