<div *ngIf="ModoAdministrador" class="row col-md-12">
  <div class="col-md-6">
    <label>Selecione a campanha</label>
    <select [(ngModel)]="CampanhaEscolhida" name="data" id="selectCampanha" class="form-control" (ngModelChange)="SelectCampanha($event)">
      <option value="" selected disabled></option>
      <option *ngFor="let Option of ListaCampanhas; i as index" [ngValue]="Option.idcampanhas">{{Option.descricao}}</option>
    </select>
  </div>
  <hr style="height: 10px;width: 100%;color: #000;">
  <br>
</div>

<div *ngIf="ModoAdministrador && dwReady" class="row col-12" style="padding-bottom: 1em; margin-bottom: 1em;">
    <div class="col-12">
        <label>Inserir arquivos</label>
    </div>
    <div class="col-3">
        <label>Descrição</label>
        <input type="text" class="form-control" placeholder="Descrição do arquivo..." [(ngModel)]="FormDownload.descricao">
    </div>
    <div class="col-2">
        <label>Arquivo</label>
        <input type="file" (change)="onFileChanged($event)">
    </div>
    <div class="col-2">
        <label>Tamanho</label> <br>
        <span>{{(FormDownload.Arquivo) ? ((FormDownload.Arquivo.size / 1024 / 1024).toFixed(2) + ' Mb') : 'N/S'}}</span>
    </div>
    <div class="col-3">
        <label>Pasta</label>
        <select class="form-control" (change)="MudarPasta($event.target.value)" [(ngModel)]="FormDownload.idpastas" [disabled]="FormDownload.iddownloads > 0">
            <option disabled value="0">Selecione *</option>
            <option value="-1">Gerenciar...</option>
            <option *ngFor="let Pasta of ListaPastas" [value]="Pasta.idpastas">{{Pasta.descricao}}</option>
        </select>
    </div>
    <div class="col-2">
        <label>Ação</label> <br>
        <button class="btn btn-success" (click)="SalvarDownload()">{{AcaoDownload}}</button>
    </div>
    <div class="col-12" style="margin-bottom: 1em; margin-top: 1em;">
        <p-table #dt2 [columns]="ColunasDownloads" [value]="ListaDownloads" [paginator]="true" [rows]="5" [responsive]="true">
            <ng-template pTemplate="caption" class="col-sm-4">
              <div style="text-align: right">
                <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
                <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt2.filterGlobal($event.target.value, 'contains')">
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th style="width: 28em;">Ações</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
              <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns">
                  <span>{{rowData[col.field]}}</span>
                </td>
                <td>
                    <button class="btn" [ngClass]="{'btn-danger' : rowData.habilitado == 1, 'btn-success' : rowData.habilitado == 0}" (click)="MudaStatusDownload(rowData)" style="margin-right: 1em;" [disabled]="FormDownload.iddownloads > 0">{{(rowData.habilitado == 1) ? 'Desabilitar' : 'Habilitar'}}</button>
                    <button class="btn btn-warning" (click)="EditarDownload(rowData)" style="margin-right: 1em;" [disabled]="FormDownload.iddownloads > 0">Editar</button>
                    <button class="btn btn-danger" (click)="ExcluirDownload(rowData)" style="margin-right: 1em;" [disabled]="FormDownload.iddownloads > 0">Excluir</button>
                    <button class="btn btn-info" (click)="BaixarArquivo(rowData)" [disabled]="FormDownload.iddownloads > 0">Baixar</button>
                </td>
              </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="dwReady" class="container-fluid row">
  <div class="row col-12">
    <div class="row col-12 title-pastas">
      Pastas
    </div>
    <div class="col-12 col-md-4 col-lg-3 my-auto" *ngFor="let Pasta of ListaPastas;i as index">
        <div class="col-12 col-md-12 pasta" (click)="OpenFolder(Pasta)">
            <div class="pasta-icon">
                <img class="icon-pasta" src="../../assets/images/downloads/pasta.svg" alt="">
            </div>
            <div class="pasta-text">
                {{Pasta.descricao}}
            </div>
        </div>
    </div>
    <hr class="col-12">
  </div>

  <div class="row col-12" *ngIf="DownloadState == 2">
    <div class="row col-12 title-pastas">
      Arquivos
    </div>
    <div class="col-6 col-md-4 my-auto" *ngFor="let Download of ActualDownloads;i as index" (click)="BaixarArquivo(Download)">
        <div class="col-12 col-md-12 pasta item">
            <div class="pasta-icon">
                <img class="icon-pasta" src="../../assets/images/downloads/download.svg" alt="">
            </div>
            <div class="pasta-text">
                {{Download.descricao}}
            </div>
        </div>
    </div>
    <div *ngIf="ActualDownloads.length == 0">Nenhum item cadastrado</div>
  </div>
</div>


<p-dialog header="Gerenciar Pastas" *ngIf="MostraPasta" [visible]="MostraPasta" (visibleChange)="MostraPasta = $event" [responsive]="true" showEffect="fade" [modal]="true">
    <div class="col-12">
      <div class="row col-12">
        <div class="col-6">
          <label>Descrição</label>
          <input type="text" class="form-control" placeholder="Nome da pasta..." [(ngModel)]="FormPasta.descricao">
        </div>
        <div class="col-6">
          <label>Ação</label> <br>
          <button class="btn btn-success" (click)="SalvarPasta()">{{AcaoPasta}}</button>
        </div>
      </div>
      <div class="row col-12" style="margin-top: 1em;">
          <div class="col-12">
            <label>Lista de Pastas</label>
          </div>
          <div class="col-12">
            <p-table #dt [columns]="ColunasPastas" [value]="ListaPastas" [paginator]="true" [rows]="10" [responsive]="true">
                <ng-template pTemplate="caption" class="col-sm-4">
                  <div style="text-align: right">
                    <i class="fa fa-search col-sm-1" style="margin:4px 4px 0 0"></i>
                    <input type="text" class="col-sm-3" pInputText placeholder="Busca" (input)="dt.filterGlobal($event.target.value, 'contains')">
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                      {{col.header}}
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                    <th>Ações</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                  <tr [pSelectableRow]="rowData">
                    <td *ngFor="let col of columns">
                      <span>{{rowData[col.field]}}</span>
                    </td>
                    <td>
                        <button class="btn" [ngClass]="{'btn-danger' : rowData.habilitado == 1, 'btn-success' : rowData.habilitado == 0}" (click)="MudaStatusPasta(rowData)" style="margin-right: 1em;" [disabled]="FormPasta.idpastas > 0">{{(rowData.habilitado == 1) ? 'Desabilitar' : 'Habilitar'}}</button>
                        <button class="btn btn-warning" (click)="EditarPasta(rowData)" style="margin-right: 1em;" [disabled]="FormPasta.idpastas > 0">Editar</button>
                        <button class="btn btn-danger" (click)="ExcluirPasta(rowData)" [disabled]="FormPasta.idpastas > 0">Excluir</button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
          </div>
      </div>
    </div>
</p-dialog>