import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CadUsuarioComponent } from './cad-usuario/cad-usuario.component';
import { CaptacaoComponent } from './captacao/captacao.component';
import { ConsultoresComponent } from './consultores/consultores.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EnsinamaisComponent } from './ensinamais/ensinamais.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginComponent } from './login/login.component';
import { ParticipacaoprovaComponent } from './participacaoprova/participacaoprova.component';
import { PlacarComponent } from './placar/placar.component';
import { ProvasComponent } from './provas/provas.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'site',
  }, {
    path: 'login',
    component: LoginComponent,
  }, {
    path: 'captacao/:dados',
    component: CaptacaoComponent,
  }, {
    path: 'cadastramento',
    component: CadUsuarioComponent,
  },{
    path: 'cadastramento/:dados',
    component: CadUsuarioComponent,
  }, {
    path: 'dashboard',
    component: DashboardComponent,
  }, {
    path: 'provas',
    component: ProvasComponent,
  }, {
    path: 'consultores',
    component: ConsultoresComponent,
  }, {
    path: 'participacao/:idleads/:idcampanhas',
    component: ParticipacaoprovaComponent,
  }, {
    path: 'site',
    component: LandingPageComponent,
  }, {
    path: 'placar',
    component: PlacarComponent,
  }, {
    path: 'torneiodegames',
    component: EnsinamaisComponent,
  }, 

];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64] // [x, y]
  }) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
