import { Component, OnInit } from '@angular/core';
import { ProvaService } from '../provas/provaService';

@Component({
  selector: 'app-placar',
  templateUrl: './placar.component.html',
  styleUrls: ['./placar.component.scss']
})
export class PlacarComponent implements OnInit {

  Ranking: any = [];

  constructor(
    private provaService: ProvaService
  ) { }

  ngOnInit(): void {
    let idcampanhas = parseInt(localStorage.getItem("idcampanhas"))
    this.provaService.getPlacar(0, idcampanhas).then(responseEst=> {
      console.log(responseEst)
      for(let i = 0; i < responseEst.length ; i++) {
        if(responseEst[i] === undefined) {
          let nullCadastro = {"escola":"","participante":"","pontuacao":""};
          this.Ranking.push(nullCadastro);
        } else {
          this.Ranking.push(responseEst[i])
        }
      }

      console.log(this.Ranking)
      this.AjustarContainer();
    })
  }

  AjustarContainer() {
    let appSelect = document.querySelector(".BaseSite");

    if(appSelect != null){
      appSelect.removeAttribute("class");
    } 
  }

}
