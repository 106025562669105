import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalFunctions } from 'src/environments/environment';
import { ResponseModel } from '../appModel';
import { PlacarEscolasModel, PlacarModel, ProvaAtualAttModel, ProvaAtualDadosModel, ProvaAtualModel, ProvasModel } from './provasModel';

@Injectable()
export class ProvaService {

    Rota: String = this.globalFunc.APIUrl() + "/apicap";

    constructor(
      private http: HttpClient,
      private globalFunc: GlobalFunctions
    ) { }

    cadastrarProva(formProva: ProvasModel) {
      return this.http.post<ResponseModel>(`${this.Rota}/cadastrarProva`, formProva)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    atualizarProva(formProva: ProvasModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/atualizarProva`, formProva)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    removerProva(idprovas: number) {
      const response = this.http
        .delete(`${this.Rota}/removerProva/${idprovas}`)
        .toPromise();
      return response;
    }

    getAllProvas() {
      return this.http.get<ProvasModel[]>(`${this.Rota}/getAllProvas`)
        .toPromise()
        .then(response => <ProvasModel[]>response)
        .then(data => {
          return <ProvasModel[]>data['response'];
        })
    }

    getAllProvasEnabled() {
      return this.http.get<ProvasModel[]>(`${this.Rota}/getAllProvasEnabled`)
        .toPromise()
        .then(response => <ProvasModel[]>response)
        .then(data => {
          return <ProvasModel[]>data['response'];
        })
    }

    getProva(idprovas: number) {
      return this.http.get<ProvasModel>(`${this.Rota}/getProva/${idprovas}`)
        .toPromise()
        .then(response => <ProvasModel>response)
        .then(data => {
          return <ProvasModel>data['response'][0];
        })
    }

    getProvaAtual() {
      return this.http.get<ProvaAtualModel>(`${this.Rota}/getProvaAtual`)
        .toPromise()
        .then(response => <ProvaAtualModel>response)
        .then(data => {
          return <ProvaAtualModel>data['response'][0];
        })
    }

    getProvaAtualDados() {
      return this.http.get<ProvaAtualDadosModel>(`${this.Rota}/getProvaAtualDados`)
        .toPromise()
        .then(response => <ProvaAtualDadosModel>response)
        .then(data => {
          return <ProvaAtualDadosModel[]>data['response'];
        })
    }

    getPlacar(idconsultores: number, idcampanhas: number) {
      return this.http.get<PlacarModel[]>(`${this.Rota}/getPlacar/${idconsultores}/${idcampanhas}`)
        .toPromise()
        .then(response => <PlacarModel[]>response)
        .then(data => {
          return <PlacarModel[]>data['response'];
        })
    }

    getPlacarEscolas() {
      return this.http.get<PlacarEscolasModel[]>(`${this.Rota}/getPlacarEscolas`)
        .toPromise()
        .then(response => <PlacarEscolasModel[]>response)
        .then(data => {
          return <PlacarEscolasModel[]>data['response'];
        })
    }

    getPlacarById(idcampanhas: number) {
      return this.http.get<PlacarModel[]>(`${this.Rota}/getPlacarById/${idcampanhas}`)
        .toPromise()
        .then(response => <PlacarModel[]>response)
        .then(data => {
          return <PlacarModel[]>data['response'];
        })
    }

    getPlacarEscolasById(idcampanhas: number) {
      return this.http.get<PlacarEscolasModel[]>(`${this.Rota}/getPlacarEscolasById/${idcampanhas}`)
        .toPromise()
        .then(response => <PlacarEscolasModel[]>response)
        .then(data => {
          return <PlacarEscolasModel[]>data['response'];
        })
    }

    atualizarProvaAtual(formProva: ProvaAtualModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/atualizarProvaAtual`, formProva)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

    setProvaAtual(provaAtt: ProvaAtualAttModel) {
      return this.http.patch<ResponseModel>(`${this.Rota}/setProvaAtual`, provaAtt)
        .toPromise()
        .then(response => <ResponseModel>response)
        .then(data => {
          return <ResponseModel>data;
        })
    }

}
