export class LeadsModel {
    idleads: number = null;
    nome: string = null;
    estado: string = "";
    cidade: string = "";
    telefone: string = null;
    email: string = null;
    datanascimento: Date = null;
    idcampanhas: number = null;
    observacao: string = null;
    idconsultores: number = null;
    confirmado: boolean = false;
    senha: string = null;
    Participacoes: ParticipacoesModel[];
    aceito: boolean = null;
}

export class ParticipacoesModel {
    idleads: number;
    nome: string;
    cidade: string;
    idpontuacao: number;
    idprovas: number;
    prova: string;
    pontuacao: number;
    data_participacao: Date;
}