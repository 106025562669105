<div class="container-fluid">
  <div class="row main-home">
    <div class="logo-turma"></div>
    <div class="logo-hero"></div>
    <div class="hero-text">
      <div class="hero-item">
        PARTICIPE <span class="grief">&nbsp;JOGANDO&nbsp;</span> MINIGAMES
        <span class="grief">&nbsp;ONLINE!&nbsp;</span>
      </div>
      <hr />
      <div class="hero-item-2" style="font-size: 1.3rem">
        Chegue entre os <span class="grief-2">&nbsp;primeiros&nbsp;</span> e
        ganhe <span class="grief-2">&nbsp;super prêmios&nbsp;</span> <br />
        e ainda se classifique para a
        <span class="grief-2">&nbsp;etapa presencial!&nbsp;</span>
      </div>
    </div>
    <a href="#form"><div class="btn btn-cadastro">SE INSCREVA!</div></a>
  </div>
</div>

<div class="container-fluid online-container">
  <div class="container mx-auto">
    <div class="row">
      <div class="col-12 text-center">
        <div class="col-9 col-md-6 col-lg-4 etapa-online mx-auto">
          PREMIAÇÃO
        </div>
      </div>
      <div class="col-11 col-md-8 mx-auto video-container">
        <img
          src="../../assets/images/premios2022.png"
          alt="Premiação"
          class="img-fluid"
        />
      </div>
      <div class="col-12 text-center">
        <div class="col-9 col-md-6 col-lg-4 etapa-online mx-auto">
          ETAPA ONLINE
        </div>
      </div>
      <div
        class="col-11 col-md-7 mx-auto embed-responsive embed-responsive-16by9 video-container"
      >
        <iframe
          class="embed-responsive-item video-iyrc"
          src="https://www.youtube.com/embed/6LhUd_87YHc"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-12 row img-container mx-auto">
        <div class="col-12 text-center" style="margin-bottom: 2rem">
          <div class="col-9 col-md-6 col-lg-4 jogos-text mx-auto" id="datas">
            JOGUE GAMES ONLINE!
          </div>
        </div>
        <div class="col-11 col-md-6 order-1 order-md-2 text-etapas mx-auto">
          <div
            class="text-center jogos-text mx-auto"
            style="font-size: 1.4rem; padding: 0 1.5rem"
          >
            ETAPA CLASSIFICATÓRIA
          </div>
          &nbsp;&nbsp;&nbsp;Etapa com duração de dois mêses, iniciando no dia
          23/09 e encerrando no dia 06/11. São 5 incríveis minigames para você
          disputar!
        </div>
        <div
          class="col-11 col-md-6 order-2 order-md-1 mx-auto"
          style="height: 18rem"
        >
          <div class="bg-game-1"></div>
          <div class="img-game">
            <img
              src="../../assets/images/games1.png"
              alt=""
              class="img-fluid game"
            />
          </div>
        </div>
        <div class="col-11 col-md-6 order-3 order-md-3 text-etapas mx-auto">
          <div
            class="text-center jogos-text mx-auto"
            style="font-size: 1.4rem; padding: 0 1.5rem"
          >
            ETAPA ELIMINATÓRIA
          </div>
          &nbsp;&nbsp;&nbsp;A etapa eliminatória foi prorrogada para os 16/11 a
          partir da 00h e com fim previsto para o dia 18/11 às 23:59h. Ela
          contará com 2 mini games para participar e se classificar!
        </div>
        <div
          class="col-11 col-md-6 order-4 order-md-4 mx-auto"
          style="height: 18rem"
        >
          <div class="bg-game-2"></div>
          <div class="img-game">
            <img
              src="../../assets/images/games2.png"
              alt=""
              class="img-fluid game"
            />
          </div>
        </div>
        <div class="col-11 col-md-6 order-5 order-md-6 text-etapas mx-auto">
          <div
            class="text-center jogos-text mx-auto"
            style="font-size: 1.4rem; padding: 0 1.5rem"
          >
            FINAL NACIONAL
          </div>
          &nbsp;&nbsp;&nbsp;A Final Nacional será realizada em um evento online
          no dia 26/11 com transmissão ao vivo, tendo início às 14h00 e término
          as 15h00 do mesmo dia. Os competidores disputarão em um mini game
          durante o evento online para decidir quem será o vencedor!
        </div>
        <div
          class="col-11 col-md-6 order-6 order-md-5 mx-auto"
          style="height: 18rem"
        >
          <div class="bg-game-3"></div>
          <div class="img-game">
            <img
              src="../../assets/images/games3.png"
              alt=""
              class="img-fluid game"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid container-app" id="download">
  <div class="container mx-auto">
    <div class="col-12 ranking-hero text-center">
      BAIXE E JOGUE COM A GENTE!
    </div>
    <div class="col-12 sub-app text-center">
      O APP do Campeonato de Robótica está disponível para Smartphones Android
      somente na Google Play Store e para Computadores com sistema operácional
      Windows.
    </div>
    <div class="col-10 embed-holder mx-auto">
      <a
        style="text-decoration: none; color: #0f3f60"
        href="https://play.google.com/store/apps/details?id=com.makerrobotics.camp"
      >
        <div class="embed-app mx-auto">
          <div class="img-app">
            <img
              class="icon-app"
              src="../../assets/images/icon-app.png"
              alt=""
            />
          </div>
          <div class="app-text">DOWNLOAD NA GOOGLE PLAY STORE</div>
        </div>
      </a>
      <a
        style="text-decoration: none; color: #0f3f60"
        href="http://campeonatoderobotica.com.br/controlversion/10/camprobotica.exe"
        download
      >
        <div class="embed-app mx-auto">
          <div class="img-app">
            <img
              class="icon-app"
              src="../../assets/images/icon-app.png"
              alt=""
            />
          </div>
          <div class="app-text">DOWNLOAD VERSÃO PARA WINDOWS</div>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="container-fluid ranking-container">
  <div class="container mx-auto">
    <div class="row">
      <div class="col-12 ranking-hero text-center">RANKING DO CAMPEONATO</div>
      <div class="col-12 col-md-5 mx-auto">
        <div class="col-12 text-center ranking-header">
          RANKING PARTICIPANTES
        </div>
        <table class="table">
          <thead>
            <th scope="col">#</th>
            <th scope="col">Nome</th>
            <th scope="col">Pontos</th>
          </thead>
          <tbody>
            <tr *ngFor="let ranking of RankingEst; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>
                <strong>{{ ranking.participante }}</strong
                ><br />
                {{ ranking.escola }}
              </td>
              <td>{{ ranking.pontuacao }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-12 col-md-5 mx-auto">
        <div class="col-12 text-center ranking-header">RANKING ESCOLAS</div>
        <table class="table">
          <thead>
            <th scope="col">#</th>
            <th scope="col">Escola</th>
            <th scope="col">Inscritos</th>
          </thead>
          <tbody>
            <tr *ngFor="let ranking of RankingEsc; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td>
                <strong>{{ ranking.unidade }}</strong>
              </td>
              <td>{{ ranking.participantes }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div id="form" class="container-fluid form-container">
  <div class="container mx-auto">
    <div class="row">
      <div class="col-12 text-center title-container">
        <div class="col-12 form-title text-center">INSCREVA-SE!</div>
        Cadastre abaixo seus dados que entraremos em contato com você!
      </div>
      <div class="col-12 btn-holder">
        <div class="col-12 text-center form-title" style="font-size: 1.5rem">
          Eu sou:
        </div>
        <div class="col-10 col-md-12 col-lg-8 btns mx-auto">
          <div
            class="btn btn-form"
            (click)="SetTipoCadastro(2)"
            [ngClass]="{ 'btn-selected': TipoCadastro === 2 }"
          >
            Proprietário de escola
          </div>
          <div
            class="btn btn-form"
            (click)="SetTipoCadastro(1)"
            [ngClass]="{ 'btn-selected': TipoCadastro === 1 }"
          >
            Estudante
          </div>
        </div>
      </div>

      <div class="row col-12 col-md-8 form mx-auto" *ngIf="TipoCadastro === 1">
        <div class="row col-md-12" style="margin: auto">
          <div class="col-md-12">
            <label>Nome</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              [(ngModel)]="Participante.nome"
            />
          </div>
          <div class="col-md-6">
            <label>Estado</label>
            <select
              class="form-control"
              [(ngModel)]="Participante.estado"
              (change)="GetIdEstado()"
            >
              <option value="" selected disabled>* Selecione o estado</option>
              <option
                *ngFor="let Estado of ListaEstados"
                [value]="Estado.Sigla"
              >
                {{ Estado.Nome }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Cidade</label>
            <select class="form-control" [(ngModel)]="Participante.cidade">
              <option value="" selected disabled>* Selecione a cidade</option>
              <option
                *ngFor="let Cidade of CidadesEstado"
                [value]="Cidade.Nome"
              >
                {{ Cidade.Nome }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Telefone</label>
            <input
              type="text"
              class="form-control"
              placeholder="(00) 00000-0000"
              [(ngModel)]="Participante.telefone"
              [dropSpecialCharacters]="false"
              mask="(00) 00000-0000"
            />
          </div>
          <div class="col-md-6">
            <label>Data de Nascimento</label>
            <input
              type="date"
              class="form-control"
              [(ngModel)]="Participante.datanascimento"
            />
          </div>
          <div class="col-md-12">
            <label>E-mail</label>
            <input
              type="email"
              class="form-control"
              placeholder="seu@email.com"
              [(ngModel)]="Participante.email"
            />
          </div>

          <div class="col-md-12" style="margin-top: 1em">
            <label *ngIf="txtInformativo != ''">{{ txtInformativo }}</label>
          </div>
          <div class="col-md-12">
            <div
              style="
                color: #fff;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
              "
            >
              <input
                type="checkbox"
                style="margin-right: 0.5rem; margin-top: 0.3rem"
                [(ngModel)]="Participante.aceito"
              />
              <label for="" style="color: #fff"
                >Eu li e aceito os
                <span class="termos" (click)="OpenTermos()"
                  >Termos de Uso e Política de Privacidade</span
                ></label
              >
            </div>
          </div>
          <div
            class="col-md-12"
            style="margin-top: 1em; display: flex; justify-content: center"
          >
            <button class="btn btn-inscrição" (click)="Cadastrar()">
              Inscrever-se
            </button>
          </div>
        </div>
      </div>

      <div
        class="form row col-12 col-md-8 form mx-auto"
        *ngIf="TipoCadastro === 2"
      >
        <div class="col-12 row mx-auto">
          <div class="col-md-12">
            <label>Nome</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              [(ngModel)]="Consultor.nome"
            />
          </div>
          <div class="col-md-6">
            <label>Estado</label>
            <select
              class="form-control"
              [(ngModel)]="Consultor.estado"
              (change)="GetIdEstado()"
            >
              <option value="" selected disabled>* Selecione o estado</option>
              <option
                *ngFor="let Estado of ListaEstados"
                [value]="Estado.Sigla"
              >
                {{ Estado.Nome }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label>Cidade</label>
            <select class="form-control" [(ngModel)]="Consultor.cidade">
              <option value="" selected disabled>* Selecione a cidade</option>
              <option
                *ngFor="let Cidade of CidadesEstado"
                [value]="Cidade.Nome"
              >
                {{ Cidade.Nome }}
              </option>
            </select>
          </div>
          <div class="col-md-12">
            <label>Unidade</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nome de sua unidade"
              [(ngModel)]="Consultor.unidade"
            />
          </div>
          <div class="col-md-12">
            <label>Telefone</label>
            <input
              type="text"
              class="form-control"
              placeholder="(00) 00000-0000"
              [(ngModel)]="Consultor.telefone"
              [dropSpecialCharacters]="false"
              mask="(00) 00000-0000"
            />
          </div>
          <div class="col-md-12">
            <label>E-mail</label>
            <input
              type="email"
              class="form-control"
              placeholder="seu@email.com"
              [(ngModel)]="Consultor.email"
            />
          </div>
          <br />
          <div class="col-md-12">
            <label *ngIf="txtInformativo != ''">{{ txtInformativo }}</label>
          </div>
          <div class="col-md-12">
            <div
              style="
                color: #fff;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
              "
            >
              <input
                type="checkbox"
                style="margin-right: 0.5rem; margin-top: 0.3rem"
                [(ngModel)]="Consultor.aceito"
              />
              <label for="" style="color: #fff"
                >Eu li e aceito os
                <span class="termos" (click)="OpenTermos()"
                  >Termos de Uso e Política de Privacidade</span
                ></label
              >
            </div>
          </div>
          <div
            class="col-md-12"
            style="
              margin-top: 1em;
              display: flex;
              justify-content: center;
              flex-direction: row;
            "
          >
            <button class="btn btn-inscrição" (click)="Cadastrar()">
              Inscrever-se
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid form-container" style="padding: 1rem 0">
  <div class="container mx-auto">
    <div class="col-12 form-title text-center">REGULAMENTO</div>
    <div class="col-12 text-center">
      <div class="btn btn-form" (click)="OpenRegulamento()">
        Ler o regulamento
      </div>
    </div>
  </div>
</div>

<div class="regulamento-modal">
  <div class="regulamento">
    <div class="row-close-reg">
      <div class="close-btn" (click)="CloseRegulamento()">X</div>
    </div>
    <div class="text-regulamento">
      <strong style="font-size: 1.2rem">
        Regras gerais do Campeonato de Robótica Estudantil Online 2022
      </strong>
      <br /><br />
      <strong>1</strong>. A CAMPEONATO DE ROBÓTICA ESTUDANTIL é um desafio
      criado para gerar entretenimento para crianças, jovens e adultos poderem,
      através de seus equipamentos com software Android, disputar através de
      “mini jogos virtuais” e buscar as melhores pontuações em cada etapa.<br /><br />
      <strong>2</strong>. O desafio, todos os jogos e software foram criados,
      desenvolvidos e idealizados pela empresa MAKER ROBOTICA E TECNOLOGIA LTDA.
      e disponibilizados nas plataformas de aplicativo e na internet de forma
      TOTALMENTE GRATUÍTA para que as escolas possam liberar o acesso, também de
      forma totalmente gratuita, aos seus alunos e pessoas de sua comunidade.<br /><br />
      <strong>3</strong>. Todos as etapas tem DATA E HORA previstos para início
      e fim. Nos reservamos o direito de atrasar, prorrogar ou cancelar um
      torneio sem aviso prévio.<br /><br />
      <strong>4</strong>. Como os mini jogos virtuais são online, ou seja,
      precisa estar conectado à rede de internet e não nos responsabilizamos por
      eventuais instabilidades e ou queda na conexão. Caso isso aconteça, o
      participante poderá aos reestabelecer a conexão, iniciar nova tentativa de
      jogada a partir do início.<br /><br />
      <strong>5</strong>. O jogador poderá realizar quantas tentativas de jogada
      desejar (dentro dos prazos pré-estabelecidos de cada etapa) para tentar
      conseguir melhores pontuações.<br /><br />
      <strong>6</strong>. O ranking estabelecido por melhor pontuação, com o
      nome dos 10 primeiros colocados será exibido na página oficial do
      campeonato: www.campeonatoderobotica.com.br.<br /><br />
      <strong>7</strong>. A pontuação conquistada em cada etapa não será
      cumulativa com as próximas. Cada etapa iniciará com a pontuação zerada.<br /><br />
      <strong>8</strong>. Empate: Se houver empate na pontuação será considerada
      a data, hora e minuto em que o jogador atingiu a pontuação. Quem tiver
      atingido a pontuação antes, será o considerado o vencedor ou
      classificado.<br /><br />
      <strong>9</strong>. O número de classificados da primeira etapa será
      baseado no cálculo de 30% do total de inscritos que jogaram.<br />
      Ex: Se houver 1000 inscritos que jogaram, serão classificados os 300
      participantes com melhores pontuações.<br /><br />
      <strong>10</strong>. A regra de classificação da segunda etapa para a
      final, se dará nos mesmos moldes da etapa anterior, ou seja, será baseado
      no cálculo de 30% do total de inscritos participarem dessa segunda
      etapa.<br /><br />
      <strong>11</strong>. Na etapa final, que acontecerá em um único dia,
      durante 1 hora, ao vivo pela internet, serão considerados vencedores os
      três primeiros colocados por maior pontuação. Os jogadores estarão
      participando simultaneamente, cada um em seu aparelho, de forma presencial
      nas escolas Ensina Mais e estará aparecendo um ranking (atualizado à cada
      1 minuto) com os 10 maiores pontuadores.<br /><br />
      <strong>12</strong>. Ao final do evento, exatamente às 15 horas do dia da
      final (horário de Brasília) o jogo será encerrado. Valerá a pontuação
      exata desse horário, minuto e segundo em que o jogo parou, ou seja, se um
      ou mais competidores ainda estiverem no meio de uma jogada, esta perderá o
      efeito de contagem de pontos e valerá a sua maior pontuação anterior.
      Leme, 01 de abril de 2021 COMITÊ ORGANIZADOR DO CAMPEONATO
    </div>
  </div>
</div>

<div class="termos-modal">
  <div class="termos-box">
    <div class="row-close-term">
      <div class="closeterm-btn" (click)="CloseTermos()">X</div>
    </div>
    <div class="text-termos">
      <strong>Termos de Uso e Política de Privacidade </strong> <br /><br />

      <strong>1.</strong> O presente Termo de Uso e Política de Privacidade
      possui como objetivo reafirmar e esclarecer os compromissos da Maker
      Robotics, bem como fixar as condições de adesão e acesso ao Campeonato de
      Robótica disponibilizado pela Maker Robotics em relação ao seu cliente,
      fornecedor e/ou consulente (“Usuário”). <br /><br />
      <strong>2.</strong> Este Termo de Uso e Política de Privacidade regula as
      disposições gerais em relação ao uso do Campeonato de Robótica
      disponibilizado pela Maker Robotics, dessa forma o serviço fornecido terá
      suas regras, condições e valores que serão fixados pelo contrato principal
      e/ou demais ordens de serviço. <br /><br />
      <strong>3.</strong> A Maker Robotics emprega as melhores práticas de
      segurança e privacidade, visando garantir a confidencialidade das
      informações conforme previsto em lei. <br /><br />
      <strong>4.</strong> É de responsabilidade do Usuário a adoção de medidas
      de segurança razoáveis nos dispositivos que utiliza para acessar o
      Campeonato de Robótica disponibilizado pela Maker Robotics. <br /><br />
      <strong>5.</strong> A coleta, o tratamento, o armazenamento e a
      disponibilização dos dados, inclusive sensíveis, e das informações e dados
      fornecidos pelo Usuário ocorrerá sempre dentro dos termos da legislação
      brasileira vigente, em especial às Leis 12.965 de 2014 e 13.709 de 2018,
      para fins de proteção do crédito, garantia da prevenção à fraude e à
      segurança do titular, nos processos de identificação e autenticação de
      cadastro em sistemas eletrônicos. <br /><br />
      <strong>6.</strong> Ao realizar o cadastro e concordar com o Termo de Uso
      e Política de Privacidade, o usuário possui ciência, concorda e autoriza
      com à coleta, tratamento e o armazenamento de seus dados e dos demais
      dados pessoais que fornece ao se cadastrar no Campeonato de Robótica
      disponibilizado pela Maker Robotics, no momento de cadastro e ao decorrer
      do uso. <br /><br />
      <strong>7.</strong> O Usuário garante que informará apenas dados corretos
      e que obteve de forma lícita ou mediante autorização, nos casos em que
      essa foi necessária. <br /><br />
      <strong>8.</strong> Cada Usuário receberá uma senha e um logon que são
      exclusivos e intransferíveis. É de responsabilidade exclusiva do Usuário o
      zelo e sigilo sobre suas informações de logon e senha. <br /><br />
      <strong>9.</strong> No caso de dados inexatos, incompletos ou
      desatualizados, o Usuário possui a ciência de que estará sujeito a
      suspensão do acesso de algumas funções, serviços fornecidos e/ou
      cancelamento pela Maker Robotics de cadastro no Campeonato de Robótica
      disponibilizado pela Maker Robotics, independente de notificação.
      <br /><br />
      <strong>10.</strong> A adesão e o acesso virtual ao Campeonato de Robótica
      disponibilizado pela Maker Robotics ocorrerá de forma gratuita, e o
      Usuário estará isento de qualquer tipo de cobrança de valores em
      decorrência da utilização de serviços, conforme estabelecido nas condições
      presentes no Campeonato de Robótica disponibilizado pela Maker Robotics,
      Ordens de Serviço e demais fatores. <br /><br />
      <strong>11.</strong> O Usuário autoriza a utilização dos dados de e-mail,
      telefone (ligações e/ou mensagens de texto) e endereço para a comunicação
      da Maker Robotics, como por exemplo, mas se se restringir, a comunicações
      acerca da alteração deste Termo de Uso e Política de Privacidade e
      propagandas de serviços fornecidos. Caso o Usuário não queira mais receber
      os comunicados da Maker Robotics, poderá solicitar o cancelamento de tais
      envios por meio do e-mail vendas2@makereducacao.com.br disponibilizado
      pela Maker Robotics. <br /><br />
      <strong>12.</strong> É de total responsabilidade do Usuário a utilização
      das informações a que teve acesso em decorrência da utilização do
      Campeonato de Robótica disponibilizado pela Maker Robotics, assegurando
      sempre que serão utilizadas de forma devida e com respaldo em lei. Em caso
      de desrespeito ao previsto nesta cláusula, o Usuário responderá
      individualmente por eventuais utilizações indevidas. <br /><br />
      <strong>13.</strong> A responsável pela coleta, tratamento e armazenamento
      das informações e dados é a MAKER ROBOTICA E TECNOLOGIA LTDA., inscrita no
      CNPJ/MF sob o nº 26.465.547/0001-56 , que é a detentora da marca Maker
      Robotics, que se encontra localizado na Rua Joaquiem de Góes, 584, CEP
      13610-108, Centro – Leme-SP. <br /><br />
      <strong>14.</strong> O Usuário é exclusivamente responsável pela exatidão
      e veracidade dos dados que forneceu, isentando a Maker Robotics em caso de
      erros ou danos que resultem destas informações errôneas. <br /><br />
      <strong>15.</strong> É responsabilidade do Usuário a constante atualização
      das informações e dados informados e/ou fornecidos a Maker Robotics.
      <br /><br />
      <strong>16.</strong> É possível que o site da Maker Robotics contenha
      links e acessos seguros que redirecionem o Usuário a sites externos,
      entretanto este Termos de Uso e a Política de Privacidade faz referência
      apenas a utilização do Campeonato de Robótica disponibilizado pela Maker
      Robotics. Dessa forma, é de responsabilidade do Usuário consultar as
      políticas de privacidade de outros sites. <br /><br />
      <strong>17.</strong> Ao aceitar este Termo de Uso e a Política de
      Privacidade, o Usuário concorda com os cookies de navegação e
      identificação do IP (Internet Protocol) no Campeonato de Robótica
      disponibilizado pela Maker Robotics, tais dados serão utilizados para
      melhoria de segurança, comunicação institucional e/ou comunicação
      comercial entre Maker Robotics e Usuário. <br /><br />
      <strong>18.</strong> O usuário poderá entrar em contato com a Maker
      Robotics através do Canal de Atendimento ao Cliente, utilizando o site da
      Maker Robotics (www.makerrobotics.com.br) por meio da assistência virtual
      ou através do e-mail vendas2@makereducacao.com.br. <br /><br />
      <strong>19.</strong> A Maker Robotics sempre entrará em contato com o
      Usuário pelos meios de comunicação oficiais. Em caso de desconfiança sobre
      um e-mail, ligação ou mensagem, o usuário deverá entrar em contato com a
      Maker Robotics através dos Canais de Atendimento acima especificados.
      <br /><br />
      <strong>20.</strong> A Maker Robotics não possui, em seus meios de
      comunicação, links que remetem a outras páginas na internet. Todas as
      informações devem estar disponíveis no próprio meio de comunicação ou seu
      site. <br /><br />
      <strong>21.</strong> A Maker Robotics assegura e garante o respeito aos
      direitos à privacidade, à proteção de dados pessoais e ao sigilo das
      comunicações privadas e dos registros. <br /><br />
      <strong>22.</strong> Todos os serviços, dados e demais fatores
      disponibilizados no Campeonato de Robótica disponibilizado pela Maker
      Robotics são protegidos por direitos autorais. É vedada a cópia com
      finalidade comercial deste Termo de Uso e Política de Privacidade ou
      alteração, modificação e/ou omissão das informações e/ou dados
      disponibilizados no Campeonato de Robótica disponibilizado pela Maker
      Robotics, bem como é vedada a reprodução de páginas. <br /><br />
      Ao selecionar o item “Li e Aceito” o usuário concorda com todas as
      disposições presentes neste Termo de Uso e Política de Privacidade para a
      navegação em qualquer Portal de Serviços disponibilizado pela Maker
      Robotics e utilização de seus produtos e serviços.
    </div>
  </div>
</div>

<div class="inscri-modal">
  <div class="inscri">
    <strong>Cadastro realizado com sucesso!</strong>
    <button
      style="
        transform: scale(0.75);
        box-shadow: none;
        height: 3rem;
        width: 8rem;
        margin-top: 1rem;
      "
      class="sucess-btn mx-auto button btn-secondary"
      (click)="RemoveModal()"
    >
      OK!
    </button>
  </div>
</div>
