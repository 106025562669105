import { GlobalFunctions } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { CidadesModel, EstadosModel } from '../compartilhado/compartilhadoModel';
import { CompartilhadoService } from '../compartilhado/compartilhadoService';
import { ParticipanteModel, ConsultorModel } from './interessesModel';
import { LandingService } from '../landing-page/landingService';
import { ProvaService } from '../provas/provaService';
import { ProvasModel } from '../provas/provasModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ensinamais',
  templateUrl: './ensinamais.component.html',
  styleUrls: ['./ensinamais.component.scss']
})
export class EnsinamaisComponent implements OnInit {

  RankingEst: any = [];
  RankingEsc: any = [];

  TipoCadastro: number = 0;
  Participante: ParticipanteModel = new ParticipanteModel();
  Consultor: ConsultorModel = new ConsultorModel();
  ListaEstados: EstadosModel[];
  ListaCidades: CidadesModel[];
  CidadesEstado: CidadesModel[];

  constructor(
    private compartilhadoService: CompartilhadoService,
    private provaService: ProvaService,
    private GlobalFunctions: GlobalFunctions,
    private LandingService: LandingService,
    private router: Router,
  ) { }



  ngOnInit(): void {
    this.AjustarContainer();

    document.title = "Campeonato de Robótica Estudantil";

    this.compartilhadoService.LerJson("assets/Estados.json").then(resEstados => {
      this.ListaEstados = resEstados;
      this.compartilhadoService.LerJson("assets/Cidades.json").then(resCidades => {
        this.ListaCidades = resCidades;

      });
    });

   

    this.provaService.getPlacar(0, 0).then(responseEst=> {
      for(let i = 0; i < 10 ; i++) {
        if(responseEst[i] === undefined) {
          let nullCadastro = {"escola":"","participante":"","pontuacao":""};
          this.RankingEst.push(nullCadastro);
        } else {
          this.RankingEst.push(responseEst[i])
        }
      }
    })

    this.provaService.getPlacarEscolas().then(responseEsc => {
      for(let i = 0; i < 10 ; i++) {
        if(responseEsc[i] === undefined) {
          let nullCadastro = {"unidade":"","participantes":""};
          this.RankingEsc.push(nullCadastro);
        } else {
          this.RankingEsc.push(responseEsc[i])
        }
      }
    })
  


  }

  
  AjustarContainer() {
    let appSelect = document.querySelector(".BaseSite");
    appSelect.removeAttribute("class");
  }

  SetTipoCadastro(e) {
    this.TipoCadastro = e;
  }

  GetIdEstado() {
    let IDEstado: number = 0;

    if(this.TipoCadastro === 1){
      for (let i = 0; i < this.ListaEstados.length; i++) {
        if (this.ListaEstados[i].Sigla == this.Participante.estado) {
          IDEstado = this.ListaEstados[i].ID;
          break;
        }
      }
    } else if(this.TipoCadastro === 2) {
      for (let i = 0; i < this.ListaEstados.length; i++) {
        if (this.ListaEstados[i].Sigla == this.Consultor.estado) {
          IDEstado = this.ListaEstados[i].ID;
          break;
        }
      }
    }
    
    this.CarregarCidades(IDEstado);
  }

  CarregarCidades(IDEstado) {
    this.CidadesEstado = new Array<CidadesModel>();
    for (let i = 0; i < this.ListaCidades.length; i++) {
      if (this.ListaCidades[i].Estado == IDEstado) {
        this.CidadesEstado.push(this.ListaCidades[i]);
      }
    }
  }

  Cadastrar() {

    if(this.TipoCadastro === 1) {
      let erro = this.GlobalFunctions.VerificaCaractereNoCampo(this.Participante, ["", null], []);

      if(erro) {
        console.log(erro)
        alert("Preencha todos os campos do formulário" + erro);
        return
      }

      this.LandingService.cadastrarParticipante(this.Participante).then(resInsert => {
        if(resInsert.status === 200) {
          this.ModalAppears();
        }
      });
    } else if (this.TipoCadastro === 2) {
      let erro = this.GlobalFunctions.VerificaCaractereNoCampo(this.Consultor, ["", null], []);

      if(erro) {
        alert("Preencha todos os campos do formulário");
        return
      }

      this.LandingService.cadastrarConsultor(this.Consultor).then(resInsert => {
        if(resInsert.status === 200) {
          this.ModalAppears();
        }
      });
    }
  }

  ModalAppears() {
    const modal = document.querySelector('.inscri-modal');
    const box = document.querySelector('.inscri');

    modal['style'].pointerEvents = "visible";

    modal['style'].display = "flex";
    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)";

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(1)"
            },400)
        },500)
    },0)
  }

  RemoveModal(){
    const modal = document.querySelector('.inscri-modal');
    const box = document.querySelector('.inscri');

    modal['style'].pointerEvents = "none";

    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)"

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(0)"
                setTimeout(() => {
                    modal['style'].backgroundColor = "rgba(39, 39, 39, 0)";
                },100)
            },400)
        },200)
    },0)
  }

  OpenRegulamento() {
    const modal = document.querySelector('.regulamento-modal');
    const box = document.querySelector('.regulamento');

    modal['style'].pointerEvents = "visible";

    modal['style'].display = "flex";
    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)";

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(1)"
            },400)
        },500)
    },0)
  }

  CloseRegulamento(){
    const modal = document.querySelector('.regulamento-modal');
    const box = document.querySelector('.regulamento');

    modal['style'].pointerEvents = "none";

    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)"

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(0)"
                setTimeout(() => {
                    modal['style'].backgroundColor = "rgba(39, 39, 39, 0)";
                },100)
            },400)
        },200)
    },0)
  }

  OpenTermos() {
    const modal = document.querySelector('.termos-modal');
    const box = document.querySelector('.termos-box');

    modal['style'].pointerEvents = "visible";

    modal['style'].display = "flex";
    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)";

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(1)"
            },400)
        },500)
    },0)
  }

  CloseTermos(){
    const modal = document.querySelector('.termos-modal');
    const box = document.querySelector('.termos-box');

    modal['style'].pointerEvents = "none";

    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)"

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(0)"
                setTimeout(() => {
                    modal['style'].backgroundColor = "rgba(39, 39, 39, 0)";
                },100)
            },400)
        },200)
    },0)
  }

  AbrirRanking() {
    this.router.navigate(['/placar']);
  }

}


  

