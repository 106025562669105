import { DOCUMENT } from '@angular/common';
import { Component, OnInit , Inject , ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalFunctions } from 'src/environments/environment';
import { CampanhaService } from '../campanhas/campanhaService';
import { CampanhasModel } from '../campanhas/campanhasModel';
import { CidadesModel, EstadosModel } from '../compartilhado/compartilhadoModel';
import { CompartilhadoService } from '../compartilhado/compartilhadoService';
import { LeadService } from './leadService';
import { LeadsModel } from './leadsModel';

@Component({
  selector: 'app-captacao',
  templateUrl: './captacao.component.html',
  styleUrls: ['./captacao.component.scss']
})
export class CaptacaoComponent implements OnInit {

  Campanha: CampanhasModel = new CampanhasModel();
  Lead: LeadsModel = new LeadsModel();
  txtInformativo: string = "";
  SucessoCadastro: boolean = false;
  NotFound: boolean = false;
  DataExpirou: boolean = false;
  DataAntecipada: boolean = false;
  ListaEstados: EstadosModel[];
  ListaCidades: CidadesModel[];
  CidadesEstado: CidadesModel[];
  ListaLeads: LeadsModel[];

  constructor(
    private route: ActivatedRoute,
    private leadService: LeadService,
    private campanhaService: CampanhaService,
    private compartilhadoService: CompartilhadoService,
    private globalFunc: GlobalFunctions,
    @Inject(DOCUMENT) private document,
    private elementRef: ElementRef,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.params['dados'].includes('-')) {
      this.Lead.idconsultores = this.route.snapshot.params['dados'].split('-')[1];
      this.Lead.idcampanhas = this.route.snapshot.params['dados'].split('-')[2];
      this.campanhaService.getCampanha(this.Lead.idcampanhas).then(resCampanha => {
        this.Campanha = resCampanha;

        let DataAtual: Date = new Date();

        if (DataAtual > new Date(this.Campanha.datafinal)) {
          this.DataExpirou = true;
          return;
        }
        else if (DataAtual < new Date(this.Campanha.datainicial)) {
          this.DataAntecipada = true;
          return;
        }
        else if (!this.Campanha.habilitado) {
          return;
        }

        this.leadService.getLeadsCampanha(this.Lead.idcampanhas).then(resLeads => {
          this.ListaLeads = resLeads;

          this.compartilhadoService.LerJson("assets/Estados.json").then(resEstados => {
            this.ListaEstados = resEstados;
            this.compartilhadoService.LerJson("assets/Cidades.json").then(resCidades => {
              this.ListaCidades = resCidades;

            });
          });
        });

      });
    }
    else {
      this.NotFound = true;
    }

    this.AjustarContainer();

    this.globalFunc.chamaScript(this.document, this.elementRef, "lottieScript.js");
  }

  GetIdEstado() {
    let IDEstado: number = 0;
    for (let i = 0; i < this.ListaEstados.length; i++) {
      if (this.ListaEstados[i].Sigla == this.Lead.estado) {
        IDEstado = this.ListaEstados[i].ID;
        break;
      }
    }
    this.CarregarCidades(IDEstado);
  }

  CarregarCidades(IDEstado) {
    this.CidadesEstado = new Array<CidadesModel>();
    for (let i = 0; i < this.ListaCidades.length; i++) {
      if (this.ListaCidades[i].Estado == IDEstado) {
        this.CidadesEstado.push(this.ListaCidades[i]);
      }
    }
  }

  Cadastrar() {
    let Erro: string = this.globalFunc.VerificaCaractereNoCampo(this.Lead, ["", null], ["idleads", "senha", "observacao", "insert_date", "update_date"]);
    console.log(Erro)
    if (Erro) {
      this.txtInformativo = "Campos obrigatórios sem preencher: " + Erro;
      return;
    }

    for (let i = 0; i < this.ListaLeads.length; i++) {
      if (this.ListaLeads[i].telefone == this.Lead.telefone) {
        this.txtInformativo = "Este TELEFONE já está cadastrado!";
        return;
      }
      else if (this.ListaLeads[i].email == this.Lead.email) {
        this.txtInformativo = "Este EMAIL já está cadastrado!";
        return;
      }
    }

    this.leadService.cadastrarLead(this.Lead).then(resCadastro => {
      if (resCadastro.status == 200) {
        this.txtInformativo = "Cadastro realizado com sucesso!";
        this.leadService.getAllLeads().then(resLeads => {
          setTimeout(() => {
            this.SucessoCadastro = true;
            this.txtInformativo = resLeads[resLeads.length - 1].senha;
          }, 500);
        });
      }
      else {
        this.txtInformativo = "Erro ao realizar cadastro!";
        if (resCadastro.error.sqlMessage.includes("Duplicate entry")) {
          this.txtInformativo = "'" + resCadastro.error.sqlMessage.split("'")[1] + "' já está cadastrado no sistema!";
        }
        setTimeout(() => {
          this.txtInformativo = "";
        }, 5000);
      }
    });
  }

  AjustarContainer() {
    let appSelect = document.querySelector("app-captacao");
    appSelect['style'].height = "100%";
    appSelect['style'].width = "100%";
  }

  OpenTermos() {
    const modal = document.querySelector('.termos-modal');
    const box = document.querySelector('.termos-box');

    modal['style'].pointerEvents = "visible";

    modal['style'].display = "flex";
    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)";

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(1)"
            },400)
        },500)
    },0)
  }

  CloseTermos(){
    const modal = document.querySelector('.termos-modal');
    const box = document.querySelector('.termos-box');

    modal['style'].pointerEvents = "none";

    setTimeout(() => {
        modal['style'].backgroundColor = "rgba(39, 39, 39, 0.8)"

        setTimeout(() => {
            box['style'].transform = "scale(1.05)"
            setTimeout(() => {
                box['style'].transform = "scale(0)"
                setTimeout(() => {
                    modal['style'].backgroundColor = "rgba(39, 39, 39, 0)";
                },100)
            },400)
        },200)
    },0)
  }

}
