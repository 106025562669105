// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
};

export class GlobalFunctions {

  APIUrl() : string {
    let URL: string = "";
    let Link: string = window.location.href.split('/')[2];

    if (window.location.href.includes("https")) {
      URL = "https://" + Link;
    }
    else {
      if (!Link.includes(":4200"))
        URL = "http://" + Link;
      else
        URL = "http://" + Link.split(':')[0] + ((environment.production) ? "" : ":1339");
    }

    return URL;
  }

  ReplaceCaracteres(Texto: string, Caracteres: string[], TrocarPor: string) : string {
    for (let i = 0; i < 100; i++) {
      for (let o = 0; o < Caracteres.length; o++) {
        Texto = Texto.replace(Caracteres[o], TrocarPor);
      }
    }
    return Texto;
  }

  VerificaCaractereNoCampo(Formulario: any, Caracteres: any[], Excecoes: any[], QtdeCampos: number = 0, Igual: boolean = true): string {
    let Resultado: string = null;
    let CamposLidos: number = 0;
    for (var Campo in Formulario) {
      for (let o = 0; o < Caracteres.length; o++) {
        let Ex: boolean = false;
        for (let u = 0; u < Excecoes.length; u++) {
          if (Campo === Excecoes[u]) {
            Ex = true;
            break;
          }
        }
        if (Ex)
          break;
        if (Igual) {
          if (Formulario[Campo] === Caracteres[o]) {
            Resultado = Campo;
            break;
          }
        }
        else {
          if (Formulario[Campo] !== Caracteres[o]) {
            Resultado = Campo;
            break;
          }
        }
      }
      CamposLidos++;
    }
    if (CamposLidos < QtdeCampos)
      Resultado = CamposLidos.toString();
    return Resultado;
  }

  uploadFiles(headers: any[], metodo: string, url: string, VariavelFinal: string) {
    localStorage.setItem(VariavelFinal, "0");
    //let tk = this.Variables("token");
    let req = new XMLHttpRequest();
    let formData = new FormData();

    for (let i = 0; i < headers.length; i++) {
      formData.append(headers[i].header, headers[i].data);
    }
    req.open(metodo, this.APIUrl() + url);
    //req.setRequestHeader("token", tk);
    req.send(formData);

    req.onreadystatechange = function() {
      if (req.readyState === 4) {
        localStorage.setItem(VariavelFinal, req.response);
      }
    }
  }

  chamaScript(document, elementRef, name, id = "") {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = '../../../../assets/scpts/' + name;
    s.id = id;
    const __this = this;
    s.onload = function () { __this.afterScriptAdded(); };
    elementRef.nativeElement.appendChild(s);
  }

  afterScriptAdded() {
    const params = {
      width: '350px',
      height: '420px',
    };
    if (typeof (window['functionFromExternalScript']) === 'function') {
      window['functionFromExternalScript'](params);
    }
  }

  JsonToTable(Json: string) {
    localStorage.setItem("JsonToTableWait", "0");
    let req = new XMLHttpRequest();
    let formData = new FormData();
    formData.append("json", Json);
    formData.append("email", "");
    formData.append("delimiter", "0");
    formData.append("nestedData", "0");
    formData.append("epoch", "false");
    formData.append("editJSON", "false");
    formData.append("statusid", "31e54310-41c6-4df4-bf4e-04411a475b4e");
    formData.append("isXML", "false");
    formData.append("isCSVToJSON", "false");

    req.open("POST", "https://json-csv.com/conversion/start");
    req.send(formData);

    req.onreadystatechange = function() {
      if (req.readyState === 4) {
        let ResponseJson = JSON.parse(req.responseText);
        //console.log(ResponseJson);
        let req2 = new XMLHttpRequest();
        let formData2 = new FormData();
        formData2.append("id", ResponseJson['id']);
        req2.open("POST", "https://json-csv.com/conversion/GetHTML");
        req2.send(formData2);
        req2.onreadystatechange = function() {
          if (req2.readyState === 4) {
            let ResponseHtml = JSON.parse(req2.responseText);
            let TabelaHTML = `<html><head><title>Relatorio</title><style>
              body { margin: 0; font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; text-align: left; background-color: #fff; }
              table { display: table; border-collapse: separate; box-sizing: border-box; border-spacing: 2px; border-color: grey; text-align: center; width: 100%; }
              th {background-color: #9AFF9A; font-weight: bold; text-align: center;}
              tbody tr:nth-child(odd) { background-color: rgba(245, 245, 245, 0.5); }
              tbody tr:nth-child(even) { background-color: rgba(255,255,255,.5); }
            </style></head><body>` + ResponseHtml['html'] + "</body></html>";
            localStorage.setItem("JsonToTableWait", TabelaHTML);
          }
        }
      }
    }

  }

  ReplaceAll(Texto: string, Palavras: string[], TrocarPor: string[]) {
    for (let o = 0; o < Texto.length; o++) {
      for (let i = 0; i < Palavras.length; i++) {
        Texto = Texto.replace(Palavras[i], TrocarPor[i]);
      }
    }
    return Texto;
  }

  ImprimirTabela(Lista: any[], AutoPrint: boolean = true) {
    var wnd = window.open("about:blank", "", "_blank");
    this.JsonToTable(JSON.stringify(Lista));
    setTimeout(() => {
      let TabelaHtml: string = this.ReplaceAll(localStorage.getItem("JsonToTableWait"), ["&lt;", "&quot;", "&gt;"], ["<", '"', ">"]);
      wnd.document.write(TabelaHtml);
      localStorage.setItem("JsonToTableWait", "");
      if (AutoPrint) {
        wnd.print();
        wnd.close();
      }
    }, 1000 + Lista.length * 10);
  }

}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
